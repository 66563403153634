.cust-cal{
    display: grid;
    grid-template-columns:   13% 13% 13% 13% 13% 13% 13%;
    div{
        padding: 5px;      
        a{
            padding: 10px;  
            text-align: center;
            width: 35%;
            display: block;
            border: 1px solid #3b3b3b;
            &.active{
                background-color: #556ee6;
                color: #fff;
                font-weight: bold;
            }
        }      
    }
}
