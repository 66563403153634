*.-morulus-customsb {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    }
    
    *.-morulus-customsb>figure:first-child {
    margin: 0px !important;
    position: absolute;
    right: 4px !important;
    width: 4px !important;
    height: 100px;
    background-color: #FFC2E9;
    }
    
    *.-morulus-customsb>*:last-child {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding-right: 20px;
    }
    
    
    #layout-wrapper .main-content .container-fluid {
    border-radius: 18px 0 0 18px!important;
    background: #fff;
    margin: 0;
    padding: 40px 30px;
    }
    
    #layout-wrapper .main-content .container-fluid .row .card {
    border-radius: 0 !important;
    box-shadow: none !important;
    }
    
    #layout-wrapper .main-content .page-content {
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: calc(50px + 24px);
    }
    
    #page-topbar .navbar-header .d-flex li.dropdown .noti-icon {
    padding-top: 18px;
    }
    
    #sidebar-menu ul li ul.sub-menu.mm-collapse.mm-show li.mm-active a.active {
    background: transparent !important;
    color: #7a7373 !important;
    font-weight: 600;
    }
    
    #layout-wrapper .vertical-menu {
    box-shadow: none !important;
    }
    
    #page-topbar .navbar-header .d-inline-block.dropdown .d-none.d-xl-inline-block.ms-1 {
    font-size: 0;
    }
    
    .navbar-header .d-flex a {
    position: relative;
    font-weight: normal !important;
    }
    
    .navbar-header .d-flex a:hover::before {
    content: "";
    width: 100%;
    height: 5px;
    background: #7556ff;
    display: inline-block;
    top: -13px;
    left: 0;
    position: absolute;
    border-radius: 0 0 6px 6px!important;
    }
    
    #sidebar-menu ul li.mm-active a.active {
    position: relative;
    }
    
    #sidebar-menu ul li.mm-active a.active::before {
    content: "";
    width: 5px;
    height: 100%;
    background: #7556ff;
    display: inline-block;
    top: 0;
    left: 5px;
    position: absolute;
    border-radius: 0!important;
    z-index: 999;
    }
    
    #sidebar-menu .mm-show.mm-active li a i {
    color: #9797a0;
    }
    
    #sidebar-menu ul li a span.badge.rounded-pill.bg-info {
    background: #5cbf44 !important;
    }
    
    #sidebar-menu ul li a .badge.rounded-pill.bg-success {
    background: #5cbf44 !important;
    }
    
    .navbar-brand-box .logo-light span.logo-lg {
    width: 100%;
    float: left;
    }
    
    .navbar-brand-box .logo-light span.logo-lg img {
    width: 120px;
    height: auto;
    float: left;
    margin-top: 10px;
    }
    
    .d-inline-block.dropdown .dropdown-menu-end.dropdown-menu a.dropdown-item::before {
    display: none !important;
    }
    
    .d-inline-block.dropdown .dropdown-menu-end.dropdown-menu a.dropdown-item:hover {
    background: none !important;
    }
    
    body[data-sidebar="dark"] #sidebar-menu ul li {
    margin-bottom: 10px;
    }
    
    body[data-sidebar="dark"] #sidebar-menu ul li a {
    font-size: 1rem !important;
    font-weight: normal!important;
    padding: 0.75rem 1.5rem;
    }
    
    body[data-sidebar="dark"] #sidebar-menu ul li a,
    body[data-sidebar="dark"] #sidebar-menu ul li a i {
    color: #9c9fb2 !important;
    }
    
    body[data-sidebar="dark"] #sidebar-menu ul li a:hover {
    color: #7a7373 !important;
    background: none !important;
    }
    
    body[data-sidebar="dark"] #sidebar-menu ul li a:hover::before,
    body[data-sidebar="dark"] #sidebar-menu ul li.mm-active a::before {
    content: "";
    width: 5px;
    height: 100%;
    background: #7556ff !important;
    display: inline-block;
    top: 0;
    left: 5px;
    position: absolute;
    border-radius: 3px!important;
    z-index: 999;
    }
    
    body[data-sidebar="dark"] #sidebar-menu ul li.mm-active ul.sub-menu li a::before {
    content: none !important;
    }
    
    #sidebar-menu ul li ul.sub-menu.mm-collapse.mm-show li.mm-active a.active::before,
    #sidebar-menu ul li ul.sub-menu.mm-collapse.mm-show li.mm-active a:hover::before {
    content: "";
    width: 5px;
    height: 100%;
    background: #7556ff !important;
    display: inline-block;
    top: 0;
    left: 5px;
    position: absolute;
    border-radius: 3px!important;
    z-index: 999;
    }
    
    body[data-sidebar="dark"] #sidebar-menu ul li a:hover i {
    color: #7a7373 !important;
    }
    
    .sidebar-menu2 {
    float: left;
    width: 100%;
    margin-top: 20px;
    }
    
    .sidebar-menu2 .search-hd {
    float: left;
    width: 100%;
    padding: 0.75rem 1.5rem;
    border-top: 3px solid #e1e3e6;
    border-bottom: 3px solid #e1e3e6;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    }
    
    .sidebar-menu2 .search-hd .search-hd-text {
    font-size: 1.1rem !important;
    color: #737c88 !important;
    float: left;
    width: auto;
    }
    
    .sidebar-menu2 .search-hd .search-icon {
    font-size: 1.4rem !important;
    color: #737c88 !important;
    float: right;
    width: auto;
    cursor: pointer;
    }
    
    .sidebar-menu2 ul li {
    list-style: none;
    float: left;
    width: 100%;
    }
    
    .sidebar-menu2 ul li a {
    font-size: 0.8rem !important;
    color: #737c88 !important;
    float: left;
    width: 100%;
    padding: 0.75rem 1.5rem;
    }
    
    .sidebar-menu2 ul li .search-sub a:hover {
    color: #fff!important;
    background: rgba(117, 86, 255, 0.6)!important;
    }
    
    form.app-search .position-relative span.bx.bx-search {
    font-size: 25px;
    top: 4px;
    }
    
    .sidebar-menu3 {
    float: left;
    width: 100%;
    margin-top: 80px;
    padding: 1rem 0;
    border-top: 3px solid #e1e3e6;
    }
    
    .sidebar-menu3 ul li {
    list-style: none;
    float: left;
    width: 100%;
    }
    
    .sidebar-menu3 ul li a {
    font-size: 1rem !important;
    color: #737c88 !important;
    float: left;
    width: 100%;
    padding: 0.75rem 1.5rem;
    position: relative;
    }
    
    .sidebar-menu3 ul li a i {
    display: inline-block;
    padding-bottom: 0.125em;
    font-size: 1.25rem;
    line-height: 1.40625rem;
    vertical-align: middle;
    color: #9c9fb2 !important;
    transition: all 0.4s;
    min-width: 2rem;
    }
    
    .sidebar-menu3 ul li a:hover {
    color: #7a7373 !important;
    background: none !important;
    }
    
    .sidebar-menu3 ul li a:hover::before {
    content: "";
    width: 5px;
    height: 100%;
    background: #7556ff;
    display: inline-block;
    top: 0;
    left: 5px;
    position: absolute;
    border-radius: 3px!important;
    z-index: 999;
    }
    
    .sidebar-menu3 ul li a:hover i {
    color: #7a7373 !important;
    }
    
    .sidebar-menu2 ul.search-list {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    }
    
    .sidebar-menu2 ul.search-list li a span {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    float: left;
    color: #fff;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-right: 10px;
    justify-content: center;
    font-size: 0.8rem !important;
    }
    
    .sidebar-menu2 ul.search-list li:nth-child(1n) a span {
    background: #7556ff;
    }
    
    .sidebar-menu2 ul.search-list li:nth-child(2n) a span {
    background: #7556ff;
    }
    
    .sidebar-menu2 ul.search-list li:nth-child(3n) a span {
    background: #42c8ff;
    }
    
    .sidebar-menu2 ul.search-list li:nth-child(4n) a span {
    background: #5a93ff;
    }
    
    .sidebar-menu2 ul.search-list li:nth-child(5n) a span {
    background: #939596;
    }
    
    .sidebar-menu2 ul.search-list li a span i {
    display: inline-block;
    margin: 0 auto;
    }
    
    .sidebar-menu2 ul.search-list li a {
    font-size: 1.1rem !important;
    color: #9c9fb2 !important;
    position: relative;
    }
    
    .sidebar-menu2 ul.search-list li a:hover,
    .sidebar-menu2 ul.search-list li.mm-active a.active {
    color: #fff!important;
    background: rgba(117, 86, 255, 0.6)!important;
    }
    
    .sidebar-menu2 ul.search-list li a:hover::before {
    content: "";
    width: 5px;
    height: 100%;
    background: #7556ff;
    display: inline-block;
    top: 0;
    left: 5px;
    position: absolute;
    border-radius: 0!important;
    z-index: 999;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .h4.card-title {
    padding: 15px 30px;
    font-size: 22px;
    color: #1d1c31;
    border-bottom: 2px solid #d9def2;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table thead th {
    font-size: 16px;
    color: #1e1c34;
    font-weight: 500;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body ul.nav-tabs-custom.nav-justified.nav.nav-tabs li.nav-item {
    float: left;
    flex-basis: inherit !important;
    flex-grow: inherit !important;
    margin-bottom: 10px!important;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body ul.nav-tabs-custom.nav-justified.nav.nav-tabs li.nav-item a {
    padding: 0.3rem 1rem;
    font-size: 16px;
    color: #1e1c34;
    font-weight: 500;
    }
    
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table.mb-0.table tbody tr:nth-child(odd) {
    background: #f7fbfd;
    border-radius: 12px !important;
    overflow: hidden;
    }
    
    #layout-wrapper .main-content .page-content .card.mytask-card .card-body .tab-content .table-responsive table.table tbody tr:nth-child(odd) {
    background: #f7fbfd;
    border-radius: 12px !important;
    overflow: hidden;
    }
    
    #layout-wrapper .main-content .page-content .card.mytask-card .card-body .tab-content .table-responsive table.table tbody tr td {
    border: none !important;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr th {
    font-size: 15px;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td {
    font-size: 14px;
    }
    
    #layout-wrapper .main-content .page-content .card.Project-card .table-responsive table.table.mb-0.table tbody td {
    border: none !important;
    }
    
    #layout-wrapper .main-content .page-content .card.Project-card .tab-content.p-3.text-muted {
    padding-top: 0 !important;
    }
    
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table .progress {
    background-color: #cdd6df !important;
    width: 75%;
    height: 0.75rem;
    position: relative;
    overflow: visible;
    }
    
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table .progress .progress-bar.bg-primary {
    background-color: #90a0b4 !important;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .badge-soft-success.badge.badge-success.badge-pill.done-color {
    background: rgba(231, 241, 253, 1);
    color: #4288c0;
    padding: 0.5em 0;
    border-radius: 50px;
    font-size: 13px !important;
    width: 100px;
    text-align: center;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .badge-soft-success.badge.badge-success.badge-pill.progess-color {
    background: rgba(241, 238, 255, 1);
    color: #7556ff;
    padding: 0.5em 0;
    border-radius: 50px;
    font-size: 13px !important;
    width: 100px;
    text-align: center;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .badge-soft-success.badge.badge-success.badge-pill.todo-color {
    background: rgba(237, 242, 245, 1);
    color: #7d8ea3;
    padding: 0.5em 0;
    border-radius: 50px;
    font-size: 13px !important;
    width: 100px;
    text-align: center;
    }
    
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table tbody tr {
    vertical-align: middle;
    }
    
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table .progress .percentage-txt {
    float: right !important;
    position: absolute;
    right: -30px;
    color: #90a0b4;
    font-size: 10px;
    }
    
    .Project-card.card .table-responsive thead tr {
    border: none !important;
    }
    
    .Project-card.card .table-responsive thead tr th {
    border: none !important;
    }
    
    .date-text i.fas.fa-calendar-alt {
    color: #7556ff;
    margin-right: 5px;
    }
    
    .mytask-card.card .tab-content>.tab-pane span.font-size-12.badge-soft-success.badge.badge-success.badge-pill.complete-color {
    background: rgba(222, 254, 233, 1);
    color: #36a95e;
    padding: 0.5em 0;
    border-radius: 50px;
    font-size: 13px !important;
    width: 100px;
    text-align: center;
    }
    
    .mytask-card.card .tab-content>.tab-pane span.font-size-12.badge-soft-success.badge.badge-success.badge-pill.done-color {
    background: #e7f1fd;
    color: #4288c0;
    padding: 0.5em 0;
    border-radius: 50px;
    font-size: 13px !important;
    width: 100px;
    text-align: center;
    }
    
    .mytask-card.card .tab-content>.tab-pane span.font-size-12.badge-soft-success.badge.badge-success.badge-pill.progess-color {
    background: #f1eeff;
    color: #7556ff;
    padding: 0.5em 0;
    border-radius: 50px;
    font-size: 13px !important;
    width: 100px;
    text-align: center;
    }
    
    .mytask-card.card .tab-content>.tab-pane span.font-size-12.badge-soft-success.badge.badge-success.badge-pill.todo-color {
    background: #edf2f5;
    color: #7d8ea3;
    padding: 0.5em 0;
    border-radius: 50px;
    font-size: 13px !important;
    width: 100px;
    text-align: center;
    }
    
    span.font-size-12.badge-soft-success.badge.badge-success.badge-pill,
    span.font-size-12.badge-soft-warning.badge.badge-warning.badge-pill {
    padding: 0.5em 1em;
    border-radius: 50px;
    font-size: 13px !important;
    }
    
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table .progress .progress-bar.bg-primary {
    border-radius: 0.25rem;
    }
    
    span.Low-text {
    color: #90a0b4;
    display: flex;
    }
    
    span.High-text {
    color: #dc5381;
    display: flex;
    }
    
    span.Medium-text {
    color: #f98723;
    display: flex;
    }
    
    .Medium-text i.dripicons-arrow-thin-right {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-flex;
    text-align: center;
    align-items: center;
    background-color: rgba(241, 180, 76, 0.18);
    margin-right: 5px;
    }
    
    .Low-text i.dripicons-arrow-thin-down {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-flex;
    text-align: center;
    align-items: center;
    background-color: #e2e8ee;
    margin-right: 5px;
    }
    
    .High-text i.dripicons-arrow-thin-up {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-flex;
    text-align: center;
    align-items: center;
    background-color: #fbdbe6;
    margin-right: 5px;
    }
    
    .Low-text i.dripicons-arrow-thin-down::before,
    .High-text i.dripicons-arrow-thin-up::before,
    .Medium-text i.dripicons-arrow-thin-right::before {
    text-align: center;
    display: inline-block;
    left: 0;
    right: 0;
    margin: 0 auto;
    }
    
    .btn-primary {
    color: #fff!important;
    border-color: #afa0f1!important;
    background: rgb(125, 97, 252)!important;
    background: linear-gradient(320deg, rgba(125, 97, 252, 1) 0%, rgba(175, 160, 241, 1) 100%)!important;
    border-radius: 2rem!important;
    }
    
    .btn-primary:hover {
    color: #fff!important;
    border-color: #afa0f1!important;
    background: rgb(175, 160, 241)!important;
    background: linear-gradient(320deg, rgba(175, 160, 241, 1) 0%, rgba(125, 97, 252, 1) 100%)!important;
    border-radius: 2rem!important;
    }
    
    .btn-sm,
    .btn-group-sm>.btn {
    border-radius: 0px !important;
    }
    
    .modal-backdrop.show {
    opacity: 0.2 !important;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td.dotmore {
    font-size: 25px;
    line-height: 3px !important;
    vertical-align: middle;
    padding-top: 0 !important;
    color: #7d9abf !important;
    }
    
    .add-project,
    .add-teamtask,
    .add-event,
    .add-task {
    float: none;
    width: 97%;
    margin: 30px 1.5% 15px 1.5%;
    margin-bottom: 15px;
    display: inline-block;
    }
    
    .add-project .btn.btn-primary,
    .add-teamtask .btn.btn-primary,
    .add-event .btn.btn-primary,
    .add-event button.popup-form.btn.btn-outline-primary.btn-lg.btn-block {
    float: left;
    width: 100%;
    text-align: center;
    color: #939292 !important;
    font-size: 15px;
    padding: 7px 0;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    border: none !important;
    border-radius: 0 !important;
    background: #fff !important;
    font-weight: normal !important;
    }
    
    .add-task .btn.btn-primary {
    float: left;
    width: 100%;
    text-align: left;
    color: #939292 !important;
    font-size: 16px;
    padding: 0 0 0 20px;
    box-shadow: none;
    border: none !important;
    border-radius: 0 !important;
    background: #fff !important;
    }
    
    .add-project .btn.btn-primary:hover,
    .add-teamtask .btn.btn-primary:hover,
    .add-event .btn.btn-primary:hover,
    .add-event button.popup-form.btn.btn-outline-primary.btn-lg.btn-block:hover,
    .add-add-event button.btn.btn-outline-primary.btn-lg.btn-block:hover {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(141, 117, 249, 0.3);
    box-shadow: 0px 0px 8px 0px rgba(141, 117, 249, 0.3);
    color: #7556ff!important;
    border: none !important;
    border-radius: 0 !important;
    background: #fff !important;
    }
    
    .add-add-event button.btn.btn-outline-primary.btn-lg.btn-block {
    float: left;
    width: 100%;
    text-align: center;
    color: #939292 !important;
    font-size: 15px;
    padding: 7px 0;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    border: none !important;
    border-radius: 0 !important;
    background: #fff !important;
    font-weight: normal !important;
    }
    
    .add-task .btn.btn-primary:hover {
    color: #7556ff!important;
    border: none !important;
    border-radius: 0 !important;
    background: #fff !important;
    }
    
    .add-task a.popup-form input.form-control.popup-checkbox {
    width: 15px;
    height: 15px;
    padding: 0 !important;
    margin-right: 10px;
    }
    
    .mytask-card .add-task {
    float: left;
    display: inline-block;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    }
    
    .mytask-card .add-task .checkbox-txt {
    padding: 0.5rem 1rem !important;
    border: 1px solid #ced3d9 !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td.sml-text {
    font-size: 12.5px!important;
    width: auto;
    padding-left: 20px !important;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td.sml-text span {
    font-size: 9px;
    color: #888;
    display: block;
    }
    
    .mytask-card .add-task .checkbox-txt .form-control.popup-checkbox {
    width: 14px!important;
    height: 14px!important;
    margin-right: 15px;
    border-radius: 2px !important;
    padding: 0 !important;
    background: #f5f5f5 !important;
    }
    
    .add-task a.popup-form {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #939292;
    font-size: 15px;
    padding-left: 15px;
    }
    
    .modal-header h5.modal-title span.popup-head {
    float: left;
    text-align: left;
    color: #9ea1a8;
    font-weight: normal;
    }
    
    .event-popup h5.modal-title {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #9ea1a8;
    }
    
    .event-popup h5.modal-title button.btn.btn-primary.right-align {
    float: right !important;
    position: absolute;
    right: 15px;
    padding: 0.47rem 1.25rem;
    }
    
    .event-popup button.close {
    display: none;
    }
    
    .teamtask-card .table-responsive tbody tr {
    vertical-align: middle;
    }
    
    .modal-dialog.event-popup.modal-dialog-centered.modal-lg,
    .modal-dialog.project-popup.modal-dialog-centered.modal-lg,
    .modal-dialog.task-popup.modal-dialog-centered.modal-lg,
    .modal-dialog.teamtask-popup.modal-dialog-centered.modal-lg {
    max-width: 500px;
    }
    
    .input-group.date-pick .form-control[readonly] {
    background: #fff;
    }
    
    .input-group.date-pick {
    position: relative;
    }
    
    .input-group.date-pick .date-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    }
    
    .input-group.date-pick .date-icon i {
    color: #9ea1a8;
    font-size: 1rem;
    }
    
    .myevent-card.card .card-body .h4.card-title {
    display: none;
    }
    
    .myevent-card.card .card-body .add-event {
    margin-top: 0;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title {
    padding: 12px 30px !important;
    font-size: 22px;
    color: #1d1c31;
    border-bottom: 1px solid #d9def2;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title h5 {
    padding: 0;
    font-size: 22px;
    color: #1d1c31;
    border-bottom: none !important;
    font-weight: 600!important;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group {
    border-radius: 3px !important;
    overflow: hidden;
    border: 1px solid #bac7cf !important;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group label.btn.btn-primary {
    border-radius: 0 !important;
    padding: 3px 10px;
    border: none !important;
    font-size: 10px;
    background: #fff !important;
    color: #bac7cf !important;
    border-left: 1px solid #bac7cf !important;
    margin-bottom: 0 !important;
    border-right: 1px solid #bac7cf !important;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group label.btn.btn-primary:nth-child(1) {
    border-radius: 3px !important;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group label.btn.btn-primary:hover,
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group label.btn.btn-primary.active {
    background: #a08efc !important;
    color: #fff !important;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-4 .btn-group {
    border: none !important;
    margin: 0 auto!important;
    }
    
    .myevent-card.card .card-body .add-event .p-4.meet-block>div {
    color: #333;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group button.dropdown-toggle.btn.btn-primary {
    border-radius: 3px!important;
    padding: 4px 20px;
    font-size: 10px;
    background: #7556ff !important;
    color: #fff !important;
    border: 1px solid #bac7cf !important;
    margin-bottom: 0 !important;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-4 {
    text-align: right;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 {
    text-align: right;
    padding-right: 0;
    padding-left: 0;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 {
    padding: 0 !important;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-6:nth-child(2) {
    padding-right: 0 !important;
    text-align: right;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-6:nth-child(2) .row {
    justify-content: space-between;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm {
    width: 20px;
    height: 23px;
    padding: 0;
    margin-top: 5px;
    border-radius: 3px !important;
    border: none !important;
    background: #e9ebf0 !important;
    text-align: center;
    color: #6e7884 !important;
    box-shadow: none !important;
    margin-left: 5px;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm i {
    font-size: 15px;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm:hover {
    background: #7556ff !important;
    color: #fff !important;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 {
    padding: 0 !important;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-6:nth-child(2) {
    padding-right: 0 !important;
    text-align: right;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-6:nth-child(2) .row {
    justify-content: space-between;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm {
    width: 20px;
    height: 23px;
    padding: 0;
    margin-top: 5px;
    border-radius: 3px !important;
    border: none !important;
    background: #e9ebf0 !important;
    text-align: center;
    color: #6e7884 !important;
    box-shadow: none !important;
    margin-left: 5px;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm i {
    font-size: 15px;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm:hover {
    background: #7556ff !important;
    color: #fff !important;
    }
    
    .add-event {}
    
    .myevent-card.card .card-body .add-event .p-4 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>a {
    width: 100% !important;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>div a {
    width: 88%;
    border: 1px solid #dadcdd;
    border-radius: 4px !important;
    font-size: 1.25rem;
    color: #333;
    font-weight: 500 !important;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>div a:first-line {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: #666;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>div a.active:first-line {
    color: #fff !important;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>div i {
    font-size: 7px !important;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>div {
    width: 14%;
    text-align: center;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>div a.active {
    color: #fff;
    background-color: #7556ff;
    }
    
    .modal-header button.close {
    display: none;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 {
    padding: 0 !important;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-6:nth-child(2) {
    padding-right: 0 !important;
    text-align: right;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-6:nth-child(2) .row {
    justify-content: space-between;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm {
    width: 20px;
    height: 23px;
    padding: 0;
    margin-top: 5px;
    border-radius: 3px !important;
    border: none !important;
    background: #e9ebf0 !important;
    text-align: center;
    color: #6e7884 !important;
    box-shadow: none !important;
    margin-left: 5px;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm i {
    font-size: 15px;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm:hover {
    background: #7556ff !important;
    color: #fff !important;
    }
    
    .add-event {}
    
    .myevent-card.card .card-body .add-event .p-4 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>a {
    width: 100% !important;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>div a {
    width: 88%;
    border: 1px solid #dadcdd;
    border-radius: 4px !important;
    font-size: 1rem;
    color: #333;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>div {
    width: 14%;
    text-align: center;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>div a.active {
    color: #fff !important;
    background-color: #7556ff;
    }
    
    .myevent-card.card .card-body .add-event .p-4 .cust-cal>div i {
    font-size: 10px;
    }
    
    .modal-header button.close {
    display: none;
    }
    
    button.btn.btn-primary.team-btn {
    padding: 0 !important;
    vertical-align: middle;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    font-size: 12px;
    }
    
    button.btn.btn-primary.team-btn span {
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    float: left;
    margin: 2px 7px 2px 2px;
    color: #7556ff;
    line-height: 30px;
    font-size: 18px;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group {
    margin: 15px 0;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-12 {
    padding: 0;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-12 .rdw-editor-wrapper .rdw-editor-toolbar.toolbarClassName {
    margin-bottom: 0;
    border-radius: 0.5rem 0.5rem 0 0 !important;
    border: 1px solid #ced4da !important;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-12 .rdw-editor-wrapper .editorClassName.rdw-editor-main {
    margin-bottom: 0;
    border-radius: 0 0 0.5rem 0.5rem !important;
    border: 1px solid #ced4da !important;
    border-top: none !important;
    height: 120px;
    padding: 10px 15px;
    }
    
    .modal-dialog .modal-content .modal-body .input-group.date-pick input.form-control,
    .modal-dialog .modal-content .modal-body select.form-control {
    border-radius: 0.5rem !important;
    border: 1px solid #ced4da;
    }
    
    .modal-dialog .modal-content .modal-body select.form-control {
    position: relative;
    }
    
    .modal-dialog .modal-content .modal-body select.form-control::after {
    content: "";
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-12 .rdw-editor-wrapper .editorClassName.rdw-editor-main .DraftEditor-root {
    float: left;
    width: 100%;
    height: 100%;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-12 .rdw-editor-wrapper .editorClassName.rdw-editor-main .DraftEditor-root .DraftEditor-editorContainer {
    height: 80%;
    overflow-y: auto;
    }
    
    .modal-dialog {
    max-width: 600px !important;
    }
    
    .modal-dialog .modal-content .modal-body .row-mt.row {
    margin: 15px 0;
    }
    
    .modal-dialog .modal-content .modal-body .row-mt.row .col-lg-6:nth-child(1) {
    padding-left: 0;
    }
    
    .modal-dialog .modal-content .modal-body .row-mt.row .col-lg-6:nth-child(2) {
    padding-right: 0;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-12 .rdw-editor-wrapper .rdw-editor-toolbar.toolbarClassName .rdw-inline-wrapper,
    .rdw-dropdown-wrapper.rdw-history-dropdown,
    .rdw-emoji-wrapper {
    opacity: 0.4;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-12 .rdw-editor-wrapper .rdw-editor-toolbar.toolbarClassName .rdw-inline-wrapper {
    display: none;
    }
    
    input.form-control::placeholder,
    select.form-control::placeholder {
    color: #9ba1a9 !important;
    }
    
    .modal-dialog .modal-content .modal-body select.form-control option {
    color: #9ba1a9 !important;
    }
    
    .modal-dialog .modal-content .modal-body .row .mb-3 {
    margin-bottom: 0 !important;
    }
    
    .modal-header button.close.task-close {
    display: inline-block;
    }
    
    .modal-body,
    .modal-footer,
    .modal-header {
    padding: 1rem 1.5rem!important;
    }
    
    .modal-dialog .modal-content .modal-body .row-datetime.row .col-lg-3:nth-child(1),
    .modal-dialog .modal-content .modal-body .row-datetime.row .col-lg-3:nth-child(3),
    .modal-dialog .modal-content .modal-body .row-datetime.row .col-lg-3:nth-child(2) {
    padding-right: 0;
    }
    
    .modal-dialog .modal-content .modal-body .input-group input.form-control.d-block.flatpickr-input,
    .modal-dialog .modal-content .modal-body .input-group-append .input-group-text {
    background: #fff;
    }
    
    .modal-footer.ftr-none {
    border: none !important;
    display: none;
    }
    
    .modal-header button.btn.btn-primary {
    padding: 0.47rem 1.5rem!important;
    }
    
    .modal-dialog .modal-content .modal-body .input-group input.form-control.d-block.input {
    background: #fff !important;
    }
    
    .avatar-group .avatar-group-item.plus-avatar-group {
    margin-left: 0px;
    }
    
    .avatar-group .avatar-group-item.plus-avatar-group .avatar-xs .avatar-title.rounded-circle.bg-secondary.text-white {
    background: transparent !important;
    color: #adbdd5 !important;
    border: 2px dashed #adbdd5 !important;
    }
    
    .time-outer.input-group {
    position: relative;
    }
    
    .time-outer.input-group .input-group-append .input-group-text {
    padding: 0;
    border: none !important;
    font-size: 16px;
    color: #acafb5;
    line-height: normal;
    }
    
    .time-outer.input-group .input-group-append {
    position: absolute;
    right: 10px;
    top: 6px;
    }
    
    .time-outer.input-group input.form-control.d-block.flatpickr-input {
    border-radius: 4px !important;
    }
    
    .modal .modal-dialog {
    max-width: 650px !important;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tr td,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tr th {
    border-color: #d9def2 !important;
    padding: 0.5rem 0.5rem!important;
    }
    
    .myevent-card.card .card-body .add-event .p-4.meet-block {
    padding: 1rem 1.5rem!important;
    }
    
    .myevent-card.card .card-body .add-event .p-4.meet-block .mb-2.col {
    background: #f7fbfd;
    padding: 0.75rem 0.75rem 0.75rem 1.5rem !important;
    position: relative;
    float: left;
    width: 100%;
    flex: unset !important;
    }
    
    .myevent-card.card .card-body .add-event .p-4.meet-block .mb-2.col::before {
    content: "";
    width: 7px;
    height: 100%;
    background: #5cbf44;
    display: inline-block;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 5px 0 0 5px!important;
    z-index: 9;
    }
    
    .myevent-card.card .card-body .add-event .p-4.meet-block .mb-2.col:nth-child(even)::before {
    content: "";
    background: #f5d949!important;
    }
    
    .myevent-card.card .card-body .add-event .p-4.meet-block .mb-2.col>div {
    font-size: 16px;
    color: #6c7482;
    }
    
    .myevent-card.card .card-body .add-event .p-4.meet-block .mb-2.col small {
    font-size: 13px;
    color: #a6abb2;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-6:nth-child(1),
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-3:nth-child(1) {
    padding-left: 0;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-6:nth-child(2),
    .modal-dialog .modal-content .modal-body .row.form-group .col-sm-3:nth-child(4) {
    padding-right: 0;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-lg-3:nth-child(1),
    .modal-dialog .modal-content .modal-body .row.form-group .col-lg-6:nth-child(1) {
    padding-left: 0;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-lg-3:nth-child(4),
    .modal-dialog .modal-content .modal-body .row.form-group .col-lg-6:nth-child(2) {
    padding-right: 0;
    }
    
    #page-topbar .navbar-header ul.topmenu {}
    
    #page-topbar .navbar-header ul.topmenu li a {
    font-size: 1.1rem;
    color: #6a7187;
    padding: 10px 15px;
    margin-left: 20px;
    transition: ease all 0.6s;
    position: relative;
    }
    
    #page-topbar .navbar-header ul.topmenu li.mm-active a.active::before,
    #page-topbar .navbar-header ul.topmenu li a:hover::before {
    content: "";
    width: 100%;
    height: 5px;
    background: #7556ff;
    display: inline-block;
    top: -13px;
    left: 0;
    position: absolute;
    border-radius: 0 0 6px 6px !important;
    }
    
    #page-topbar .navbar-header ul.topmenu {
    float: left;
    width: auto !important;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    }
    
    #page-topbar .navbar-header ul.topmenu li {
    display: inline-block !important;
    float: left;
    width: auto;
    }
    
    #page-topbar .navbar-header ul.topmenu li.mm-active a {
    color: #1d262e !important;
    }
    
    #page-topbar .navbar-header ul.topmenu li.mm-active a i {
    color: #1d262e !important;
    }
    
    .d-flex.mm-active button.header-item.mm-active {
    color: #6a7187 !important;
    }
    
    .d-flex.mm-active button.header-item.mm-active i.fa.fa-fw.fa-bars {
    color: #6a7187 !important;
    }
    
    #layout-wrapper .main-content .container-fluid .row .teamtask-card.card .table-responsive table.table tbody tr:hover {
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    }
    
    #layout-wrapper .main-content .container-fluid .row .teamtask-card.card .table-responsive table.table tbody tr {
    position: relative;
    }
    
    #layout-wrapper .main-content .container-fluid .row .teamtask-card.card .table-responsive table.table tbody tr:hover td,
    #layout-wrapper .main-content .container-fluid .row .teamtask-card.card .table-responsive table.table tbody tr:hover td.sml-text span {
    color: #7556ff !important;
    }
    
    #layout-wrapper .main-content .container-fluid .row .teamtask-card.card .table-responsive table.table tbody tr:hover::after {
    content: "";
    width: 5px;
    height: 100%;
    background: #7556ff !important;
    display: inline-block;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 4px 0 0 4px!important;
    }
    
    .mytask-card.card .tab-content.p-3.text-muted {
    padding-left: 0 !important;
    padding-right: 0 !important;
    }
    
    body.vertical-collpsed.sidebar-enable #layout-wrapper .vertical-menu #sidebar-menu ul li a span {
    display: none !important;
    }
    
    body.vertical-collpsed.sidebar-enable #layout-wrapper .vertical-menu .sidebar-menu2 .search-hd .search-hd-text {
    display: none !important;
    }
    
    body.vertical-collpsed.sidebar-enable #layout-wrapper .vertical-menu #sidebar-menu ul li ul.sub-menu {
    display: none !important;
    }
    
    body.vertical-collpsed.sidebar-enable #layout-wrapper .vertical-menu .sidebar-menu2 .search-list li a {
    font-size: 0 !important;
    }
    
    body.vertical-collpsed.sidebar-enable #layout-wrapper .vertical-menu .sidebar-menu3 ul li a span {
    display: none !important;
    }
    
    #layout-wrapper .main-content .container-fluid .mytask-card.card .nav-tabs-custom .nav-item .nav-link::after {
    display: none !important;
    }
    
    #layout-wrapper .main-content .container-fluid .mytask-card.card .nav-tabs-custom .nav-item .nav-link {
    border-radius: 50px!important;
    padding: 0.5rem 1rem;
    }
    
    #layout-wrapper .main-content .container-fluid .mytask-card.card .nav-tabs-custom .nav-item .nav-link:hover,
    #layout-wrapper .main-content .page-content .card .card-body ul.nav-tabs-custom.nav-justified.nav.nav-tabs li.nav-item .active.nav-link {
    background: #d9d3f9!important;
    color: #7556ff!important;
    border-radius: 50px!important;
    }
    
    #layout-wrapper .main-content .page-content .card.mytask-card .card-body .tab-content .table-responsive table.table tbody tr {
    position: relative;
    }
    
    #layout-wrapper .main-content .page-content .card.mytask-card .card-body .tab-content .table-responsive table.table tbody tr.blue-color::after {
    content: "";
    width: 5px;
    height: 100%;
    background: #7556ff !important;
    display: inline-block;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 4px 0 0 4px!important;
    }
    
    #layout-wrapper .main-content .page-content .card.mytask-card .card-body .tab-content .table-responsive table.table tbody tr.sky-color::after {
    content: "";
    width: 5px;
    height: 100%;
    background: #42c7ff !important;
    display: inline-block;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 4px 0 0 4px!important;
    }
    
    #layout-wrapper .main-content .page-content .card.mytask-card .card-body .tab-content .table-responsive table.table tbody tr td:first-child {
    padding-left: 35px !important;
    }
    
    .mytask-card .add-task .checkbox-txt span {
    float: left;
    width: 90%;
    }
    
    .mytask-card .add-task .checkbox-txt span input.form-control {
    border: none !important;
    padding: 0 !important;
    }
    
    .event-meet-out.row {
    position: relative;
    margin-bottom: 10px;
    background: #f7fbfd;
    }
    
    .event-meet-out.row:nth-child(odd)::after {
    content: "";
    width: 6px;
    height: 100%;
    background: #5cbe43 !important;
    display: inline-block;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 4px 0 0 4px !important;
    }
    
    .event-meet-out.row:nth-child(even)::after {
    content: "";
    width: 6px;
    height: 100%;
    background: #f5d949 !important;
    display: inline-block;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 4px 0 0 4px !important;
    }
    
    .event-meet-out.row .event-left {
    padding: 0.75rem 1rem 0.75rem 1.5rem;
    }
    
    .event-meet-out.row .event-right {
    padding: 0.75rem 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    }
    
    .event-meet-out.row .event-left .event-headtxt {
    color: #6c7379;
    font-size: 15px;
    }
    
    .event-meet-out.row .event-left small {
    color: #a6a9ae;
    }
    
    .event-meet-out.row .event-right .event-time {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    float: left;
    }
    
    .event-meet-out.row .event-right .event-time i {
    color: #037b46;
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;
    }
    
    .event-meet-out.row .event-right .event-tag {
    background: #7556ff;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    float: right;
    border-radius: 3px;
    font-size: 9px;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td.selection-cell {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td.selection-cell input.form-control {
    width: 12px;
    padding: 0 !important;
    height: 12px;
    float: left;
    background: #efefef;
    }
    
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td.selection-cell .tm-task-chk {
    float: left;
    width: 85%;
    }
    
    .modal .modal-dialog .modal-body input.form-control {
    border-radius: 0.5rem !important;
    }
    
    .modal .modal-dialog .modal-body .input-group .input-group-append {
    position: absolute;
    right: 0;
    }
    
    .modal .modal-dialog .modal-body .input-group .input-group-append span.input-group-text {
    border: none !important;
    background: transparent !important;
    }
    
    .modal .modal-dialog button.btn.btn-primary {
    padding: 0.47rem 1.5rem;
    }
    
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm,
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 .btn-group-sm>button.btn.btn-primary i {
    line-height: 25px;
    }
    
    .select-icon.col-lg-4 {
    position: relative;
    }
    
    .select-icon.col-lg-4 .sort-icon {
    position: absolute;
    right: 25px;
    top: 10px;
    }
    
    .sidebar-menu2 ul li button.btn.btn-primary {
    color: #333 !important;
    border: none!important;
    background: none !important;
    border-radius: 0 !important;
    padding: 0.75rem 1.5rem !important;
    float: left;
    width: 100%;
    text-align: left;
    position: relative;
    }
    
    .sidebar-menu2 ul li button.btn.btn-primary .addteam-form {
    font-size: 1.1rem !important;
    color: #9c9fb2 !important;
    position: relative;
    }
    
    .sidebar-menu2 ul li button.btn.btn-primary .addteam-form i {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    float: left;
    color: #fff;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-right: 10px;
    justify-content: center;
    font-size: 0.8rem !important;
    background: #949597;
    }
    
    .sidebar-menu2 ul li button.btn.btn-primary:hover,
    .sidebar-menu2 ul li button.btn.btn-primary:focus,
    .sidebar-menu2 ul li button.btn.btn-primary:active {
    color: #fff !important;
    background: rgba(117, 86, 255, 0.6) !important;
    box-shadow: none!important;
    outline: none !important;
    }
    
    .sidebar-menu2 ul li button.btn.btn-primary:hover .addteam-form,
    .sidebar-menu2 ul li button.btn.btn-primary:hover .addteam-form,
    .sidebar-menu2 ul li button.btn.btn-primary:focus .addteam-form,
    .sidebar-menu2 ul li button.btn.btn-primary:active .addteam-form {
    color: #fff !important;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .col-lg-12 {
    padding-left: 0;
    padding-right: 0;
    }
    
    .sidebar-menu2 ul li button.btn.btn-primary:hover::after {
    content: "";
    width: 5px;
    height: 100%;
    background: #7556ff;
    display: inline-block;
    top: 0;
    left: 5px;
    position: absolute;
    border-radius: 0 !important;
    z-index: 999;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone {
    border: 1px solid #ced4da;
    min-height: 36px;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone .dz-message.needsclick {
    border-radius: 0.5rem !important;
    padding: 0.47rem 0.75rem;
    float: left;
    width: 100%;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone .dz-message.needsclick .dz-message.needsclick {
    padding: 0 !important;
    font-size: 14px;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone {
    border: 1px solid #ced4da;
    min-height: 36px;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone .dz-message.needsclick {
    border-radius: 0.5rem !important;
    padding: 0.47rem 0.75rem;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone .dz-message.needsclick .dz-message.needsclick {
    padding: 0 !important;
    font-size: 14px;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone .dz-message.needsclick .dz-message.needsclick p {
    float: left;
    margin: 0 !important;
    color: #a6abb2;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone .dz-message.needsclick .dz-message.needsclick .mb-3.avatar-icon {
    float: right;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone .dz-message.needsclick .dz-message.needsclick .mb-3.avatar-icon i {
    font-size: 1.3rem;
    margin-top: -2px;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone-previews {
    margin: 0 !important;
    }
    
    .modal-dialog .modal-content .modal-body .row.form-group .dropzone-previews .dz-processing.dz-image-preview.dz-success.dz-complete.card {
    margin-top: 15px !important;
    border: 1px solid #ced4da !important;
    }
    
    .projects-page {
    margin-top: 100px;
    }
    
    .projectpage.container-fluid {
    padding: 0 !important;
    }
    
    .projectpage .card .card-body {
    padding-top: 0;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs {
    border: none !important;
    float: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link {
    font-size: 18px;
    font-weight: 400;
    align-items: center;
    display: flex;
    position: relative;
    padding: 1.5rem 1rem 1rem 1rem;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link i {
    font-size: 24px;
    margin-right: 5px;
    color: #bcc0c7;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link.active::after,
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link:hover::after {
    content: "";
    width: 100%;
    height: 5px;
    background: #7556ff;
    display: inline-block;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 0 0 6px 6px !important;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item {
    position: relative;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item::before {
    content: "";
    width: 2px;
    height: 22px;
    background: #f4f5f7;
    position: absolute;
    top: 27px;
    left: 0;
    z-index: 1;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.active.nav-link i,
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link:hover i {
    color: #7556ff;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.active.nav-link,
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link:hover {
    color: #7556ff !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 1.5rem 1rem 1rem 1rem;
    }
    
    .projectpage.container-fluid {
    padding-top: 0 !important;
    }
    
    .projectpage .card .card-body {
    padding: 0 !important;
    }
    
    .projectpage .card .card-body .project-head {
    float: left;
    margin-right: 15px;
    margin-top: 18px;
    margin-left: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    }
    
    .projectpage .card .card-body .project-head h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 !important;
    }
    
    .projectpage .card .card-body .project-head h2 .prjt-ltr {
    background: #7c828d;
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    line-height: 36px;
    font-size: 20px;
    margin-right: 10px;
    }
    
    .projectpage .card .card-body .accordion .accordion-item {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #ebedf1 !important;
    background: none !important;
    border-radius: 0 !important;
    width: 100%;
    float: left;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-header {
    color: #606368 !important;
    background: none !important;
    width: auto;
    float: left;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-header .accordion-button {
    background: transparent !important;
    color: #606368 !important;
    font-weight: 600;
    font-size: 18px;
    padding: 2rem 1rem 2rem 1.7rem;
    box-shadow: none !important;
    border: none !important;
    }
    
    .projectpage .card .card-body .tab-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    background: #fafbfc;
    border-top: 1px solid #eff1f4;
    float: left;
    width: 100%;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .container-fluid {
    background: transparent !important;
    padding: 0 !important;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background: transparent !important;
    border-top: 1px solid #eff1f4;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body table.table {
    background: transparent !important;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body table.table thead {
    background: transparent !important;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body table.table thead tr th,
    .projectpage .card .card-body .accordion .accordion-item .accordion-body table.table thead tr {
    background: #fafbfc !important;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table {
    background: transparent !important;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .table-responsive {
    background: transparent !important;
    }
    
    .projectpage .card .card-body .tab-content .tab-pane {
    padding-left: 30px;
    padding-right: 30px;
    }
    
    span.accordion-sub span {
    font-size: 12px;
    font-weight: normal;
    color: #9e9e9e;
    margin-left: 25px;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table .options {
    display: flex!important;
    flex-wrap: wrap!important;
    align-items: right!important;
    justify-content: flex-end;
    align-items: center;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table .options a i {
    color: #848891;
    font-size: 20px;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table .options a.text-success i.bx.bx-check {
    color: #00ba57;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr td {
    color: #6a7480!important;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tr th:last-child,
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tr td:last-child {
    text-align: right;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .badge-soft-success.badge.badge-success.badge-pill {
    background: #f1eeff;
    color: #7556ff;
    padding: 0.5em 0;
    border-radius: 50px;
    font-size: 13px !important;
    width: 100px;
    text-align: center;
    }
    
    .projectpage .card .card-body .new-prjt-btn {
    float: right;
    margin-top: 15px;
    margin-right: 30px;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link i.mdi.mdi-apps {
    line-height: 15px;
    font-size: 20px;
    }
    
    #layout-wrapper .main-content .projectpage.container-fluid {
    overflow: hidden;
    padding: 0 !important;
    background: transparent !important;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    left: 0 !important;
    position: absolute;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-header .accordion-button::after {
    left: 0 !important;
    position: absolute;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body table.table th:nth-child(2),
    .projectpage .card .card-body .accordion .accordion-item .accordion-body table.table td:nth-child(2) {
    width: 20%;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body table.table tr {
    border-top: 10px solid #fafbfc;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr td .progress-bar .progress {
    width: 72% !important;
    height: 0.8rem !important;
    background: #d2d9e1;
    position: relative;
    overflow: visible !important;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr td .progress-bar .progress .percentage-txt {
    font-size: 12px !important;
    right: -30px !important;
    color: #90a0b4 !important;
    position: absolute;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr td .progress .progress-bar.bg-primary {
    background-color: #90a0b4 !important;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr td .progress .progress-bar.bg-primary {
    border-radius: 0.25rem;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr td .progress-bar {
    background: transparent !important;
    }
    
    .project-task-head.row {
    padding-top: 15px;
    }
    
    .projectpage .project-task-head.row {
    padding: 15px 0 25px 0;
    border-bottom: 1px solid #dbdcdd;
    }
    
    .projectpage .project-task-head.row .project-head h1 {
    font-size: 1.4rem;
    color: #000;
    font-weight: 600;
    }
    
    .projectpage .project-task-head.row .new-prjt-btn {
    float: left;
    margin-left: 20px;
    }
    
    .projectpage .card .card-body .project-head i.dripicons-folder {
    color: #ddd;
    margin-right: 10px;
    font-size: 22px;
    font-weight: normal;
    line-height: 15px;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link i.dripicons-list {
    font-size: 18px;
    }
    
    .project-task-head.row {
    padding-top: 15px;
    }
    
    .projectpage .project-task-head.row {
    padding: 15px 0 25px 0;
    margin: 0;
    border-bottom: 1px solid #dbdcdd;
    }
    
    .projectpage .project-task-head.row .project-head h1 {
    font-size: 1.4rem;
    color: #000;
    font-weight: 600;
    }
    
    .projectpage .project-task-head.row .new-prjt-btn {
    float: left;
    margin-left: 20px;
    }
    
    .projectpage .card .card-body .project-head i.dripicons-folder {
    color: #ddd;
    margin-right: 10px;
    font-size: 22px;
    font-weight: normal;
    line-height: 15px;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link i.dripicons-list {
    font-size: 18px;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    }
    
    ul.project-task-rghtlink {
    float: right;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 18px 0 0 0;
    padding: 0;
    margin-right: 30px;
    }
    
    ul.project-task-rghtlink li {
    display: inline-block;
    font-size: 15px;
    padding: 7px 15px;
    border-radius: 6px;
    margin-left: 20px;
    border: 1px solid #e9ebf0;
    color: #898c90;
    }
    
    ul.project-task-rghtlink li.grey-bg {
    background: #e9ebf0;
    }
    
    ul.project-task-rghtlink li.white-bg {
    background: #fff;
    }
    
    .new-prjt-btn button.btn-primary.btn.btn-primary {
    padding: 0.47rem 1.25rem;
    }
    
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr td input[type="checkbox"] {
    background: #ddd !important;
    }
    
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr {
    position: relative;
    }
    
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr:hover {
    color: #7556ff !important;
    background: #fff !important;
    --bs-table-accent-bg: #fff !important;
    }
    
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr:hover p,
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr:hover h5,
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr:hover .date-text {
    color: #7556ff !important;
    }
    
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr:hover::after {
    content: "";
    width: 5px;
    height: 100%;
    background: #7556ff !important;
    display: inline-block;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 4px 0 0 4px !important;
    }
    
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr:hover td {
    background-color: #fff !important;
    }
    
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr td:first-child {
    position: relative;
    padding-left: 30px!important;
    }
    
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr td:first-child::after {
    content: "\F01DB";
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 22px;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 13px;
    left: 5px;
    z-index: 1;
    color: #ddd;
    }
    
    .projectpage.project-taskpage .card .card-body .accordion .accordion-item .accordion-body .react-bootstrap-table tbody tr:hover td:first-child::after {
    color: #7556ff !important;
    }
    
    .main-content .full-calendar {
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: 0;
    min-height: 650px;
    }
    
    .main-content .full-calendar .container-fluid {
    border-radius: 0!important;
    background: #fff;
    margin: 0;
    padding: 40px 0!important;
    }
    
    .full-calendar .project-task-head .project-head {
    float: left;
    margin-right: 30px;
    margin-top: 4px;
    margin-left: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    }
    
    .full-calendar .project-task-head.row {
    padding: 15px 0 25px 0;
    margin: 0 0 15px 0;
    border-bottom: none !important;
    }
    
    .full-calendar .project-task-head .project-head h1 {
    font-size: 1.4rem;
    color: #000;
    font-weight: 600;
    margin: 0;
    }
    
    .full-calendar .fc-button-group button.fc-button.fc-button-primary {
    border-radius: 0!important;
    padding: 7px 15px;
    font-size: 11px;
    background: #fff!important;
    color: #bac7cf!important;
    margin-bottom: 0!important;
    border: 1px solid #d8dad9!important;
    }
    
    .full-calendar .fc-button-group button.fc-timeGridDay-button.fc-button.fc-button-primary:hover,
    .full-calendar .fc-button-group button.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active,
    .full-calendar .fc-button-group button.fc-timeGridDay-button.fc-button.fc-button-primary:active,
    .full-calendar .fc-button-group button.fc-timeGridDay-button.fc-button.fc-button-primary:focus {
    background: #a08efc!important;
    color: #fff!important;
    box-shadow: none !important;
    outline: none !important;
    }
    
    .full-calendar .fc-button-group button.fc-button.fc-button-primary:focus,
    .full-calendar .fc-button-group button.fc-button.fc-button-primary:active {
    background: #a08efc!important;
    color: #fff!important;
    box-shadow: none !important;
    outline: none !important;
    }
    
    .full-calendar .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    padding-top: 25px;
    padding-left: 30px;
    height: 100%;
    }
    
    .full-calendar .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    justify-content: left;
    justify-content: left;
    padding-bottom: 20px;
    border-bottom: 2px dashed #eeeeee;
    }
    
    .full-calendar .fc-toolbar-chunk {
    border-radius: 0!important;
    padding: 0;
    background: #fff!important;
    margin-left: 30px!important;
    }
    
    .full-calendar .fc-toolbar-chunk h2.fc-toolbar-title {
    font-size: 11px;
    color: #bac7cf!important;
    border: 1px solid #d8dad9!important;
    padding: 7px 15px;
    }
    
    .full-calendar .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk:nth-child(3) {
    float: right!important;
    border: 1px solid #eeeeee!important;
    padding: 7px 15px;
    display: none!important;
    }
    
    .full-calendar .calendar-row .cal-left-block {
    margin: 0;
    }
    
    .full-calendar .calendar-row .cal-left-block h3.head {
    padding: 10px 30px;
    }
    
    .full-calendar .calendar-row .cal-left-block form {
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    }
    
    .full-calendar .calendar-row .cal-left-block form .row.form-group {
    margin: 0 !important;
    }
    
    .full-calendar .calendar-row .cal-left-block form .row.form-group input.form-control {
    border: none !important;
    padding: 10px 10px !important;
    }
    
    .full-calendar .calendar-row .cal-left-block h4.sub-head.mt-3 {
    margin: 0 !important;
    padding: 15px 20px;
    border-bottom: 1px solid #eeeeee;
    }
    
    .full-calendar .calendar-row .cal-left-block .meet-sect {
    margin: 10px 0;
    padding: 15px 20px 20px 20px;
    border-bottom: 1px solid #eeeeee;
    text-align: left;
    float: left;
    width: 100%;
    }
    
    .full-calendar .calendar-row .cal-left-block .meet-sect span {
    width: 44px;
    height: 44px;
    background: #eee;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    float: left;
    margin-right: 20px;
    }
    
    .full-calendar .calendar-row .cal-left-block .meet-sect span i {
    font-size: 22px;
    line-height: 44px;
    color: #999;
    }
    
    .full-calendar .calendar-row .cal-left-block .meet-sect p .text-success.text-center {
    text-align: left !important;
    padding-top: 10px;
    }
    
    .full-calendar .calendar-row .col-md-3 {
    padding-right: 0 !important;
    border-top: 1px solid #eeeeee !important;
    }
    
    .full-calendar .calendar-row .col-md-9 {
    padding-left: 0 !important;
    border: 1px solid #eeeeee !important;
    }
    
    .full-calendar .calendar-row .col-md-9 table.fc-col-header {
    border: none !important;
    }
    
    .full-calendar .calendar-row .col-md-9 table.fc-col-header th,
    .full-calendar .calendar-row .col-md-9 table.fc-col-header td {
    border: none !important;
    }
    
    .full-calendar .calendar-row .col-md-9 table.fc-scrollgrid.fc-scrollgrid-liquid {
    border: none !important;
    }
    
    .fc-scrollgrid-sync-inner a.fc-col-header-cell-cushion .text-uppercase {
    color: #aab2b5;
    font-weight: 600;
    }
    
    .fc-scrollgrid-sync-inner a.fc-col-header-cell-cushion .week-day-head {
    color: #5e656f;
    }
    
    .full-calendar .calendar-row .col-md-9 .fc-timegrid-col.fc-day .fc-timegrid-col-frame .fc-timegrid-col-events .fc-v-event {
    background-color: #7556ff !important;
    border: 1px solid #7556ff !important;
    padding: 10px;
    }
    
    .fc-media-screen .fc-scrollgrid-section .fc-timegrid-slots table tr .fc-timegrid-slot {
    height: 5em!important;
    border-bottom: none !important;
    }
    
    .projectpage .card .card-body .tab-content .tab-pane.calendar-tab-pane {
    padding: 0 !important;
    }
    
    .main-content .full-calendar .container-fluid {
    padding-top: 0 !important;
    }
    
    .main-content .full-calendar .container-fluid .fc .fc-timegrid-col.fc-day-today {
    background: transparent;
    border: none !important;
    }
    
    .main-content .full-calendar .container-fluid .fc-theme-standard td,
    .main-content .full-calendar .container-fluid .fc-theme-standard th {
    border: none !important;
    }
    
    .main-content .full-calendar .container-fluid .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    font-size: 16px;
    color: #5e656f;
    font-weight: 600;
    text-transform: uppercase;
    padding-left: 0 !important;
    }
    
    .main-content .full-calendar .container-fluid .fc-timegrid-slots table tr:nth-child(odd) td.fc-timegrid-slot {
    border-top: 2px dashed #eeeeee !important;
    }
    
    .main-content .full-calendar .container-fluid .fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: left !important;
    }
    
    .main-content .full-calendar .container-fluid .fc-scrollgrid-sync-table tr td .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    min-height: 5em;
    border-top: 2px dashed #eeeeee !important;
    border-right: 2px dashed #eeeeee !important;
    }
    
    .main-content .full-calendar .container-fluid .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-events {
    background: #7556ff;
    color: #fff!important;
    }
    
    .main-content .full-calendar .container-fluid .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-events .fc-daygrid-event {
    color: #fff!important;
    }
    
    .main-content .full-calendar .container-fluid .fc-daygrid-day-events .fc-daygrid-event-harness .fc-daygrid-dot-event.fc-event-mirror {
    background: #f2f3f8;
    color: #fff!important;
    }
    
    .main-content .full-calendar .container-fluid .fc .fc-daygrid-day.fc-day-today {
    background: transparent!important;
    }
    
    .main-content .full-calendar .container-fluid .fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-past {
    background: #f2f3f8!important;
    }
    
    .projectpage .card .card-body ul.nav.nav-tabs li.nav-item a.nav-link i.bx.bx-grid-alt {
    font-size: 21px;
    }
    
    .chat-page .chat-head .media {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    }
    
    .chat-page .chat-head .media .chat-head-left {
    width: 50%;
    display: flex;
    }
    
    .chat-page .chat-head .media .chat-head-left .align-self-center.me-3 img.avatar-xs.rounded-circle {
    height:2.5rem;
    width: 2.5rem;
    }
    .chat-page .chat-head .media .chat-head-right ul.chat-head-ul li:last-child {
        margin-right: 0;
    }
    .chat-page .chat-head .media .chat-head-left .media-body h5 {
    font-size: 18px !important;
    color: #39424d;
    font-weight: 400;
    }
    
    .chat-page .chat-head .media .chat-head-right {
    float: right;
    }
    
    .chat-page .chat-head .media .chat-head-right ul.chat-head-ul {
    margin: 0;
    padding: 0;
    }
    
    .chat-page .chat-head .media .chat-head-right ul.chat-head-ul li {
    list-style: none;
    display: inline-block;
    }
    
    .chat-page .chat-head .media .chat-head-right ul.chat-head-ul li a {
    font-size: 24px;
    color: #848891;
    padding: 0 10px;
    }
    
    .chat-page .chat-head .media .chat-head-right ul.chat-head-ul li a i.bx.bx-check {
    color: #00ba57;
    }
    
    .chat-page .chat-head .media .chat-head-left .media-body p.text-muted.mb-0 {
    color: #a1adba !important;
    font-size: 15px;
    }
    
    .chat-page .chat-conver-outer {
    background: #f5f5f5;
    padding-left: 4%;
    padding-right: 4%;
    margin-right: 0 !important;
    height: 100%;
    }
    
    .chat-page .user-chat .card .chat-head {
    border-bottom: 1px solid #dbdee8 !important;
    padding: 1rem 4% 1rem 4%;
    position: sticky;
    left: auto;
    width: 100%;
    background: #fff;
    z-index: 999;
    }
    
    .chat-page .user-chat .card {
    position: relative;
    float: left;
    width: 100%;
    margin: 0 !important;
    height: 100%;
    }
    
    .chat-conver-outer .chat-conversation .right .conversation-list .ctext-wrap {
    color: #fff !important;
    border-color: #afa0f1 !important;
    background: #7d61fc !important;
    background: linear-gradient( 320deg, #7d61fc 0%, #afa0f1 100%) !important;
    border-radius: 50px 3px 0 50px !important;
    }
    
    .wide-modalsize .chat-page {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    height: 100%;
    }
    
    .wide-modalsize .chat-page .chat-leftsidebar {
    float: left;
    width: 35%;
    background: #fff;
    position: relative;
    }
    
    .wide-modalsize .chat-page .user-chat {
    float: right;
    width: 65%;
    }
    
    .chat-conver-outer .chat-conversation .right .conversation-list .ctext-wrap .conversation-name,
    .chat-conver-outer .chat-conversation .right .conversation-list .ctext-wrap p {
    color: #fff;
    font-size: 0.9rem;
    }
    
    .chat-conver-outer .chat-conversation .conversation-list .ctext-wrap {
    background: #fff;
    border-radius: 3px 50px 50px 0 !important;
    }
    
    .chat-conver-outer .chat-conversation .conversation-list .ctext-wrap .conversation-name,
    .chat-conver-outer .chat-conversation .conversation-list .ctext-wrap p {
    font-size: 0.9rem;
    }
    
    .chat-page .user-chat .chat-conver-outer .p-3.chat-input-section {
    background: #fff;
    border-radius: 8px;
    padding: 10px !important;
    position: sticky;
    bottom: 20px;
    left: 0;
    width: 100%;
    right: 0;
    margin: 0 auto;
    }
    
    .chat-page .user-chat .chat-conver-outer .p-3.chat-input-section input.form-control.chat-input {
    background: #fff !important;
    border: none !important;
    }
    
    .chat-page .user-chat .chat-conver-outer .chat-input-section button.btn-rounded.chat-send {
    background: none !important;
    border: none !important;
    width: auto !important;
    min-width: inherit !important;
    color: #7556ff !important;
    font-size: 22px;
    padding: 0 !important;
    }
    
    .chat-page .chat-conversation .chat-day-title .title {
    background: transparent;
    color: #9fabb8;
    }
    
    .chat-page .chat-conversation .chat-day-title .title {
    background: transparent;
    color: #9fabb8;
    }
    
    .chat-page .chat-leftsidebar .breadcrumb-sect {
    background: #f5f5f5;
    border-radius: 1rem;
    border: 1px solid #dadada;
    float: left;
    width: 100%;
    margin: 1rem 0 25px 0;
    }
    
    .chat-page .chat-leftsidebar .breadcrumb-sect ul {
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    }
    
    .chat-page .chat-leftsidebar .breadcrumb-sect ul li {
    list-style: none;
    text-align: center;
    color: #bbbbbc;
    display: inline-block;
    padding: 8px 10px 8px 18px;
    position: relative;
    font-size: 0.75rem;
    }
    
    .chat-page .chat-conver-outer .chat-conversation.p-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 100%;
    max-height: 67vh;
    overflow-y: auto;
    }
    
    .chat-page .chat-leftsidebar .breadcrumb-sect ul li a {
    color: #bbbbbc;
    }
    
    .chat-page .chat-leftsidebar .breadcrumb-sect ul li::after {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 17px;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\F0142";
    position: absolute;
    left: 0;
    top: 4px;
    }
    
    .chat-page .chat-leftsidebar .create-sect ul {
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    }
    
    .chat-page .chat-leftsidebar .create-sect {
    float: left;
    width: 100%;
    background: #fff;
    border-radius: 1rem;
    border: 1px solid #dadada;
    margin: 0 0 20px 0;
    }
    
    .chat-page .chat-leftsidebar .create-sect ul li {
    list-style: none;
    text-align: left;
    color: #bbbbbc;
    display: inline-block;
    padding: 15px 3%;
    position: relative;
    font-size: 0.9rem !important;
    }
    
    .chat-page .chat-leftsidebar .create-sect ul li span {
    width: 100%;
    float: left;
    }
    
    .chat-page .chat-leftsidebar .create-sect ul li .blue-text {
    color: #7556ff;
    }
    
    .chat-page .chat-leftsidebar .create-sect ul li a.creat-done {
    background: #e7f1fd;
    color: #5a93ff;
    padding: 0.5em 0;
    border-radius: 50px;
    font-size: 13px !important;
    width: 90px;
    text-align: center;
    display: inline-block;
    font-weight: 600;
    }
    
    .chat-page .chat-leftsidebar .create-sect ul li::after {
    content: "";
    position: absolute;
    left: -15%;
    top: 18px;
    background: #dadada;
    width: 1px;
    height: 26px;
    }
    
    .chat-page .chat-leftsidebar .create-sect ul li:nth-child(1)::after {
    content: none !important;
    }
    
    .chat-page .chat-leftsidebar .create-sect ul li:nth-child(1) {
    width: 36% !important;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task {
    float: left;
    width: 100%;
    background: #fff;
    margin: 20px 0 20px 0;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task h2.chat-task-h2,
    .chat-page .chat-leftsidebar .chatleft-subtask h2.chat-task-h2 {
    color: #414141;
    font-weight: 500;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task .form-group {
    float: left;
    width: 100%;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task .form-group .rdw-editor-toolbar.toolbarClassName {
    position: absolute;
    bottom: 15px;
    right: 20px;
    border: none !important;
    display: none;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task .form-group .rdw-editor-toolbar.toolbarClassName .rdw-inline-wrapper {
    display: none;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task .form-group .editorClassName.rdw-editor-main {
    border-radius: 1rem;
    border: 1px solid #dadada;
    padding: 15px 15px;
    overflow-y: hidden;
    max-height: 120px;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task .form-group .editorClassName.rdw-editor-main .public-DraftEditorPlaceholder-root {
    font-size: 1rem;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task .form-group .editorClassName.rdw-editor-main .DraftEditor-editorContainer .DraftEditor-root {
    overflow: hidden;
    }
    
    .chat-page .chat-leftsidebar .chatleft-subtask {
    float: left;
    width: 100%;
    background: #fff;
    margin: 20px 0 20px 0;
    }
    
    .chat-page .chat-leftsidebar .chatleft-subtask h2.chat-task-h2 .blue-bg {
    color: #fff;
    font-size: 16px;
    background: #7556ff;
    height: 25px;
    width: 25px;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    line-height: 25px;
    }
    
    .chatleft-subtask .newsubtask {
    float: left;
    width: 100%;
    margin: 15px 0;
    padding: 18px 30px;
    border-radius: 1rem;
    border: 1px solid #dadada;
    display: flex;
    font-size: 0.8rem;
    flex-wrap: wrap;
    align-items: center;
    color: #aeaeae;
    }
    
    .chatleft-subtask .newsubtask input.form-control {
    width: 20px;
    height: 20px;
    padding: 0 !important;
    margin-right: 15px;
    background: #d3d3d3;
    border: 1px solid #d3d3d3;
    }
    
    .chat-page .chat-leftsidebar.me-lg-4 {
    padding-right: 0;
    margin-right: 0 !important;
    width: 35%;
    background: #fff;
    float: left;
    position: relative;
    overflow: hidden;
    }
    
    .chatleft-subtask .table-responsive {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    }
    
    .chatleft-subtask .table-responsive input[type="checkbox"] {
    width: 18px;
    height: 18px;
    padding: 0;
    margin: 0;
    border: 1px solid #dadada !important;
    }
    
    .chatleft-subtask .table-responsive td,
    .chatleft-attach td {
    border: none !important;
    font-size: 0.9rem;
    color: #414141;
    }
    
    .chatleft-subtask .table-responsive td i,
    .chatleft-attach td i {
    font-size: 1.2rem;
    }
    
    .chatleft-subtask .table-responsive td i.bx.bx-check {
    color: #00ba66;
    }
    
    .chatleft-subtask .newsubtask input[type="checkbox"],
    .chatleft-attach input[type="checkbox"] {
    width: 18px;
    height: 18px;
    padding: 0;
    margin: 0 15px 0 0;
    border: 1px solid #dadada !important;
    }
    
    .chatleft-attach table td.grey-txt {
    color: #999!important;
    }
    
    .chatleft-attach table td:first-child,
    .chatleft-subtask .table-responsive td:first-child {
    width: 50px;
    }
    
    .chat-page .chat-leftsidebar .chatleft-attach {
    float: left;
    width: 100%;
    margin: 0 0 25px 0;
    }
    
    .chat-page .chatleft-dropfiles {
    padding: 20px 6%;
    float: left;
    width: 100%;
    font-size: 0.9rem;
    color: #999;
    box-shadow: 0px -6px 12px 0px rgba(0, 0, 0, 0.1);
    position: sticky;
    bottom: 0;
    }
    
    .chat-page .chatleft-dropfiles span {
    float: left;
    }
    
    .chat-page .chatleft-dropfiles a.browse-link {
    float: right;
    color: #7556ff;
    padding: 0;
    border-bottom: 1px solid #00bc95;
    }
    
    .chat-page .chatleft-dropfiles span i {
    font-size: 1.4rem;
    color: #999;
    margin-right: 15px;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task .wrapperClassName.rdw-editor-wrapper {
    position: relative;
    }
    
    .chat-page .chat-leftsidebar .chat-leftsidebar-first {
    max-height: 83vh;
    padding-left: 5%;
    padding-right: 5%;
    float: left;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    }
    
    .d-lg-flex.chat-page {
    display: flex !important;
    flex-wrap: wrap;
    }
    
    .chat-page .w-100.user-chat {
    width: 65% !important;
    float: right;
    overflow: hidden;
    }
    
    .chat-page .w-100.user-chat .card {
    height: 100%;
    }
    
    .chat-bottom-row.row {
    padding: 30px 0 20px 0;
    }
    
    .chat-bottom-row.row .chat-bottom-icons .chat-input-links ul li a {
    color: #ced4e9;
    font-size: 1.3rem;
    }
    
    .chat-bottom-row.row .chat-bottom-icons .chat-input-links ul li a:hover {
    color: #7556ff;
    }
    
    .chat-page .chat-conver-outer .chat-conversation.p-3 ul.list-unstyled.mb-0 {
    height: 100% !important;
    }
    
    .chat-page .chat-conver-outer .chat-conversation.p-3 ul.list-unstyled.mb-0 .scrollbar-container {
        height: 100% !important;
        padding-right: 0;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task .form-group.editor-wrap {
    position: relative;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task .form-group.editor-wrap ul.list-inline.editor-ions {
    position: absolute;
    right: 25px;
    bottom: 55px;
    z-index: 9;
    }
    .chat-page .chat-leftsidebar .chatleft-task .form-group.editor-wrap button.btn-rounded.chat-send.w-md.btn.btn-primary {
        margin-top: 15px;
    }
    .chat-page .chat-leftsidebar .chatleft-task .form-group.editor-wrap ul.list-inline.editor-ions li a {
    color: #ced4e9;
    font-size: 1.3rem;
    }
    
    .chat-page .chat-leftsidebar .chatleft-task .form-group.editor-wrap ul.list-inline.editor-ions li a:hover {
    color: #7556ff;
    }
    
    .chat-input-section-outer {
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    border: none !important;
    }
    
    .modal .modal-dialog.wide-modalsize.modal-dialog-centered {
    max-width: 80% !important;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    height: 90vh;
    overflow: hidden;
    top: 50%;
    transform: translateY(-50%);
    min-height: 90vh !important;
    margin: 0 auto !important;
    max-height: 89vh !important;
    }
    
    .modal .modal-dialog.wide-modalsize.modal-dialog-centered .modal-content {
    border-radius: 24px 24px 0 0 !important;
    background: #f5f5f5 !important;
    }
    
    .wide-modalsize .modal-header {
    display: none !important;
    }
    
    .modal .modal-dialog.wide-modalsize.modal-dialog-centered .modal-content .modal-body {
    height: 100vh;
    position: static !important;
    flex: inherit !important;
    display: inline-table;
    overflow: hidden;
    }
    
    .wide-modalsize .modal-body {
    padding: 0 !important;
    }
    
    .wide-modalsize .modal-body .page-content {
    padding: 0;
    }
    
    .wide-modalsize .modal-body .page-content .container-fluid {
    padding: 0 !important;
    }
    
    .wide-modalsize .modal-body .page-content .container-fluid .d-lg-flex.chat-page .chat-leftsidebar.me-lg-4 .chat-leftsidebar-first {
    padding-left: 6%;
    }
    
    .modal .modal-dialog.wide-modalsize.modal-dialog-centered .modal-content {
    height: 100%;
    overflow: hidden;
    }
    
    .login-rightside .lg-sidebg {
    display: inline-block;
    width: 100%;
    height: 100%;
    }
    
    .login-rightside .lg-sidebg img.loginrightbg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
    
    .login-rightside .lg-sidetxt {
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 73%;
    }
    
    .login-rightside .lg-sidetxt .h2.card-title {
    color: #fff;
    font-size: 3.5rem;
    font-weight: 400;
    margin: 0;
    }
    
    .login-rightside {
    position: relative;
    }
    
    #root {
    height: 100vh;
    }
    
    .loginpage {
    height: 100vh;
    }
    
    .loginpage .login-leftside,
    .loginpage {
    background: #fff;
    }
    
    .loginpage .justify-content-center.row {
    margin: 0 !important;
    height: 100%;
    }
    
    .loginpage .justify-content-center.row .login-leftside.col-md-6,
    .loginpage .justify-content-center.row .login-rightside.col-md-6 {
    padding: 0 !important;
    }
    
    .loginpage .login-leftside .card {
    margin: 0 !important;
    height: 100%;
    box-shadow: none !important;
    position: relative;
    max-height: 100vh;
    }
    
    .loginpage .login-leftside .card .loginform.card-body {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 500px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    padding: 0;
    background: #fff;
    }
    
    .loginpage .login-leftside .card .signin-ftr {
    position: absolute;
    bottom: 0;
    padding: 1.25rem 1.25rem;
    width: 500px;
    left: 0;
    right: 0;
    margin: 0 auto;
    }
    
    .loginpage .form-head {
    float: left;
    width: 100%;
    }
    
    .loginpage .form-head a.logo-form {
    display: inline-block;
    margin: 0 0 30px 0;
    }
    
    .loginpage .form-head a.logo-form img {
    width: 35px;
    height: 35px;
    }
    
    .loginpage .form-head .h2.card-title {
    color: #808b90;
    font-size: 2.2rem;
    float: left;
    width: 100%;
    margin: 0 0 25px 0;
    }
    
    .loginpage .form-head p {
    float: left;
    width: 100%;
    font-size: 1rem;
    color: #000;
    margin: 0 0 0 0;
    }
    
    .loginpage .form-feilds {
    float: left;
    width: 100%;
    }
    
    .loginpage .form-feilds .gogle-sect {
    float: left;
    width: 100%;
    margin: 40px 0;
    }
    
    .gogle-sect h5.font-size-14 {
    color: #a4a7ab;
    font-weight: 400;
    font-size: 13px !important;
    }
    
    .loginpage .form-feilds .gogle-sect a.googlelink {
    display: inline-block;
    width: 100%;
    padding: 0;
    line-height: 55px;
    height: 55px;
    border-radius: 50px;
    border: 1px solid #cecece;
    font-size: 1.2rem;
    font-weight: 500;
    color: #495057;
    margin: 0 0 20px 0;
    }
    
    .loginpage .form-feilds .gogle-sect .gogle-sect-txt p {
    margin: 0 auto !important;
    display: inline-block;
    font-size: 13px;
    font-weight: normal;
    color: #a4a7ab;
    background: #fff;
    width: auto;
    position: relative;
    z-index: 9;
    padding: 0 15px;
    float: none;
    }
    
    .loginpage .form-feilds .gogle-sect .gogle-sect-txt {
    display: inline-block;
    width: 100%;
    position: relative;
    text-align: center;
    }
    
    .loginpage .form-feilds .gogle-sect .gogle-sect-txt::after {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    height: 1px;
    width: 100%;
    background: #a4a7ab;
    }
    
    .loginpage .loginform .form-feilds .mb-3 {
    float: left;
    width: 100%;
    margin-bottom: 1.5rem !important;
    }
    
    .loginpage .loginform .form-feilds .form-group label {
    font-size: 1rem;
    }
    
    .loginpage .loginform .form-feilds .form-group input.form-control {
    height: 50px;
    padding: 0 20px;
    line-height: 50px;
    border-radius: 50px;
    border: 1px solid #cecece;
    font-size: 1rem;
    color: #495057;
    }
    
    .loginpage .loginform .form-feilds .form-group input.form-control::placeholder {
    color: #a4a7ab !important;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check {
    float: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0.9rem;
    font-weight: normal;
    color: #495057;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt a.text-muted {
    float: right;
    color: #7556ff !important;
    font-weight: 500;
    font-size: 1rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background: #eff3f5;
    }
    
    .loginpage .form-feilds button.btn.btn-primary.btn-block {
    display: inline-block;
    width: 100%;
    padding: 0;
    line-height: 55px;
    height: 55px;
    border-radius: 50px;
    font-size: 1.2rem;
    font-weight: normal;
    color: #fff;
    margin: 0;
    }
    
    .loginpage .form-feilds .gogle-sect a.googlelink:hover {
    border: 1px solid #cecece;
    background: #cecece;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt a.text-muted:hover {
    color: #495057 !important;
    }
    
    .login-rightside .lg-sidebg img.loginrightbg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
    
    .login-rightside .lg-sidetxt {
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 73%;
    }
    
    .login-rightside .lg-sidetxt .h2.card-title {
    color: #fff;
    font-size: 3.5rem;
    font-weight: 400;
    margin: 0;
    }
    
    .login-rightside {
    position: relative;
    }
    
    #root {
    height: 100vh;
    }
    
    .loginpage {
    height: 100vh;
    }
    
    .loginpage .login-leftside,
    .loginpage {
    background: #fff;
    }
    
    .loginpage .justify-content-center.row {
    margin: 0 !important;
    height: 100%;
    }
    
    .loginpage .justify-content-center.row .login-leftside.col-md-6,
    .loginpage .justify-content-center.row .login-rightside.col-md-6 {
    padding: 0 !important;
    }
    
    .loginpage .login-leftside .card {
    margin: 0 !important;
    height: 100%;
    box-shadow: none !important;
    position: relative;
    }
    
    .loginpage .login-leftside .card .loginform.card-body {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 500px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    z-index: 99;
    }
    
    .loginpage .login-leftside .card .signin-ftr {
    position: absolute;
    bottom: 0;
    padding: 1.25rem 1.25rem;
    width: 500px;
    left: 0;
    right: 0;
    margin: 0 auto;
    }
    
    .loginpage .form-head {
    float: left;
    width: 100%;
    }
    
    .loginpage .form-head a.logo-form {
    display: inline-block;
    margin: 0 0 30px 0;
    }
    
    .loginpage .form-head a.logo-form img {
    width: 55px;
    height: 55px;
    }
    
    .loginpage .form-head .h2.card-title {
    color: #808b90;
    font-size: 2.2rem;
    float: left;
    width: 100%;
    margin: 0 0 25px 0;
    }
    
    .loginpage .form-head p {
    float: left;
    width: 100%;
    font-size: 1rem;
    color: #000;
    margin: 0 0 0 0;
    }
    
    .loginpage .form-feilds {
    float: left;
    width: 100%;
    }
    
    .loginpage .form-feilds .gogle-sect {
    float: left;
    width: 100%;
    margin: 40px 0;
    }
    
    .gogle-sect h5.font-size-14 {
    color: #a4a7ab;
    font-weight: 400;
    font-size: 13px !important;
    }
    
    .loginpage .form-feilds .gogle-sect a.googlelink {
    display: inline-block;
    width: 100%;
    padding: 0;
    line-height: 55px;
    height: 55px;
    border-radius: 50px;
    border: 1px solid #cecece;
    font-size: 1.2rem;
    font-weight: 500;
    color: #495057;
    margin: 0 0 20px 0;
    }
    
    .loginpage .form-feilds .gogle-sect .gogle-sect-txt p {
    margin: 0 auto !important;
    display: inline-block;
    font-size: 13px;
    font-weight: normal;
    color: #a4a7ab;
    background: #fff;
    width: auto;
    position: relative;
    z-index: 9;
    padding: 0 15px;
    float: none;
    }
    
    .loginpage .form-feilds .gogle-sect .gogle-sect-txt {
    display: inline-block;
    width: 100%;
    position: relative;
    text-align: center;
    }
    
    .loginpage .form-feilds .gogle-sect .gogle-sect-txt::after {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    height: 1px;
    width: 100%;
    background: #a4a7ab;
    }
    
    .loginpage .loginform .form-feilds .mb-3 {
    float: left;
    width: 100%;
    margin-bottom: 1.5rem !important;
    }
    
    .loginpage .loginform .form-feilds .form-group label {
    font-size: 1rem;
    }
    
    .loginpage .loginform .form-feilds .form-group input.form-control {
    height: 50px;
    padding: 0 20px;
    line-height: 50px;
    border-radius: 50px;
    border: 1px solid #cecece;
    font-size: 1rem;
    color: #495057;
    }
    
    .loginpage .loginform .form-feilds .form-group input.form-control::placeholder {
    color: #a4a7ab !important;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check {
    float: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0.9rem;
    font-weight: normal;
    color: #495057;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt a.text-muted {
    float: right;
    color: #7556ff !important;
    font-weight: 500;
    font-size: 1rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    }
    
    .loginpage .form-feilds button.btn.btn-primary.btn-block {
    display: inline-block;
    width: 100%;
    padding: 0;
    line-height: 55px;
    height: 55px;
    border-radius: 50px;
    font-size: 1.2rem;
    font-weight: normal;
    color: #fff;
    margin: 0 0 20px 0;
    }
    
    .avatar-group .avatar-group-item .avatar-xs {
    overflow: hidden;
    display: inline-block;
    }
    
    .navbar-header .d-flex button.btn.btn-primary {
    font-size: 1.1rem;
    color: #6a7187 !important;
    background: none !important;
    transition: ease all 0.6s;
    position: relative;
    font-weight: normal !important;
    border: none !important;
    padding: 10px 15px;
    margin-left: 20px;
    }
    
    .navbar-header .d-flex button.btn.btn-primary:hover,
    .navbar-header .d-flex button.btn.btn-primary:active,
    .navbar-header .d-flex button.btn.btn-primary:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    color: #131313!important;
    }
    
    .myevent-card.card .card-body .add-event .add-eventbtn {
    float: none;
    width: 97%;
    margin: 30px 1.5% 15px 1.5%;
    margin-bottom: 15px;
    display: inline-block;
    }
    
    .myevent-card.card .card-body .add-event .add-eventbtn button.btn.btn-outline-primary {
    float: left;
    width: 100%;
    text-align: center;
    color: #939292 !important;
    font-size: 15px;
    padding: 7px 0;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    border: none !important;
    border-radius: 0 !important;
    background: #fff !important;
    font-weight: normal !important;
    }
    
    .myevent-card.card .card-body .add-event .add-eventbtn button.btn.btn-outline-primary:hover {
    box-shadow: 0px 0px 8px 0px rgba(141, 117, 249, 0.3);
    color: #7556ff !important;
    border: none !important;
    border-radius: 0 !important;
    background: #fff !important;
    }
    
    #layout-wrapper .main-content .page-content .teamtask-card.card .table-responsive table.table tbody,
    #layout-wrapper .main-content .page-content .Project-card card .table-responsive table.table tbody {
    max-height: 180px;
    overflow-y: auto;
    display: table-caption;
    }
    
    .logout-poup .logout-icon {
    background: #f0edfe;
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8367f9;
    font-size: 22px;
    }
    
    .logout-poup h2 {
    color: #808b90;
    font-size: 1.6rem;
    float: left;
    width: 100%;
    margin: 20px 0;
    font-weight: 600;
    }
    
    .logout-poup p {
    float: left;
    width: 100%;
    color: #808b90;
    font-size: 15px;
    }
    
    .logout-poup .logout-btn-sect {
    float: left;
    width: 100%;
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    overflow: hidden;
    }
    
    .logout-poup .modal-body {
    padding: 2rem 2rem !important;
    }
    
    .logout-poup .logout-btn-sect a.cancel-btn {
    width: 50%;
    text-align: center;
    border-radius: 50px 0 0 50px;
    height: 55px;
    line-height: 55px;
    background: #fff;
    color: #555!important;
    font-size: 1rem;
    }
    
    .logout-poup .logout-btn-sect a.cancel-btn:hover {
    color: #fff!important;
    background: #999!important;
    background: linear-gradient(320deg, #999, #ccc)!important;
    }
    
    .logout-poup .logout-btn-sect a.logout-btn {
    width: 50%;
    text-align: center;
    border-radius: 0 50px 50px 0;
    height: 55px;
    line-height: 55px;
    background: #7d61fc!important;
    background: linear-gradient(320deg, #7d61fc, #afa0f1)!important;
    color: #fff!important;
    font-size: 1rem;
    }
    
    .logout-poup .logout-btn-sect a.logout-btn:hover {
    background: #afa0f1!important;
    background: linear-gradient(320deg, #afa0f1, #7d61fc)!important;
    color: #fff!important;
    }
    
    
    .sidebar-menu2 ul.search-list li.li-child ul.sub-menu {
    float: left;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    position: static !important;
    display: none !important;
    }
                    
    .sidebar-menu2 ul.search-list li.li-child:hover ul.sub-menu {
    display: inline-block !important;
    }
    .projectpage .card .card-body .add-new-btn {
    float: left;
    margin-top: 15px;
    margin-left: 30px;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .sub-popup-sect {
    float: left;
    margin-top: 33px;
    }
    
    .projectpage .card .card-body .accordion .accordion-collapse {
    float: left;
    width: 100%;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .sub-popup-sect .sub-popup {
    display: inline-block;
    margin: 0 20px;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .sub-popup-sect .sub-popup button.add-prjt-btn {
    background: none !important;
    border: none !important;
    color: #777;
    outline: none !important;
    box-shadow: none !important;
    }
    
    .projectpage .card .card-body .accordion .accordion-item .sub-popup-sect .sub-popup button.add-prjt-btn:hover,
    .projectpage .card .card-body .accordion .accordion-item .sub-popup-sect .sub-popup button.add-prjt-btn:focus,
    .projectpage .card .card-body .accordion .accordion-item .sub-popup-sect .sub-popup button.add-prjt-btn:active {
    background: none !important;
    border: none !important;
    color: #7556ff!important;
    outline: none !important;
    box-shadow: none !important;
    }
    
    .account-pages.loginpage {
    overflow: hidden;
    }
    
    #layout-wrapper footer.footer {
    position: static !important;
    margin-left: 250px;
    }
    
    .vertical-collpsed.sidebar-enable #layout-wrapper footer.footer {
    margin-left: 50px;
    }
    
    body.vertical-collpsed.sidebar-enable #layout-wrapper .vertical-menu .sidebar-menu2 .search-list li button.btn.btn-primary .addteam-form {
    font-size: 0 !important;
    }
    ul.project-task-rghtlink li.grey-bg i {
    margin-right: 10px;
    }
    #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body .options .text-danger, 
    #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body .options .account-add, 
    #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body .options .account-delete {
        color: #9aa0b4 !important;
        font-size: 1.2rem !important;
    }
    #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body .options .text-success{
        font-size: 1.2rem !important;
    }
    #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr {
        background: #fff;
        vertical-align: middle;
    }
    // #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr::before {
    //     content: "\F01DB";
    //     display: inline-block;
    //     font: normal normal normal 24px/1 "Material Design Icons";
    //     font-size: 22px;
    //     text-rendering: auto;
    //     line-height: inherit;
    //     -webkit-font-smoothing: antialiased;
    //     -moz-osx-font-smoothing: grayscale;
    //     width: 20px;
    //     height: 20px;
    //     position: absolute;
    //     top: 13px;
    //     left: -25px;
    //     z-index: 1;
    //     color: #ddd;
    //     }
        
    //     #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr:hover::before {
    //     color: #7556ff !important;
    //     }
        #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr {
            position: relative;
        }
        #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr::after {
            content: "";
            display: inline-block;
            width: 5px;
            height: 100%;
            position: absolute;
            background: #7556ff;
            top: 0px;
            left: 0px;
            z-index: 1;
            border-radius: 4px 0 0 4px!important;
        }
            
            #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr:hover::after {
                background: #7556ff;
            }
    
            #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr td .progress-bar {
                background: transparent !important;
            }
            #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr td .progress-bar .progress {
                width: 72% !important;
                height: 0.8rem !important;
                background: #d2d9e1;
                position: relative;
                overflow: visible !important;
            }
            #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr td .progress .progress-bar.bg-primary {
                border-radius: 0.25rem;
                background-color: #90a0b4 !important;
            }
            #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr td .progress-bar .progress .percentage-txt {
                font-size: 12px !important;
                right: -30px !important;
                color: #90a0b4 !important;
                position: absolute;
            }
            #layout-wrapper .main-content .page-content .projectpage.project-newpage .card .card-body .table-responsive table.table.mb-0.table tbody tr td:nth-child(1) {
                padding-left: 20px !important;
            }
            .projectpage .card .card-body .accordion .accordion-item .accordion-body .badge-soft-success.badge.badge-success.badge-pill.complete-color {
                background: #e7f9ed;
                color: #34c38f;
                padding: 0.5em 0;
                border-radius: 50px;
                font-size: 13px !important;
                width: 100px;
                text-align: center;
            }
            #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr.done-tr::after {
                background: #50a5f1!important;
            }
            #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr.complete-tr::after {
                background: #34c38f!important;
            }
            #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr.todo-tr::after {
                background: #90a0b4!important;
            }
            #layout-wrapper .main-content .project-newpage .tab-content .tab-pane .accordion-body table tbody tr.progess-tr::after {
                background: #7556ff!important;
            }
    
    .teamchatpage .teamchat-left .dropdown button.btn.dropdown-toggle {
        background: none !important;
        border: none !important;
        text-align: left;
        width: 100% !important;
        color: #000;
        font-size: 1rem;
        align-items: center;
        white-space: inherit !important;
        padding:10px 20px !important;
        justify-content: space-between;
        border: none!important;
        outline: none!important;
        box-shadow: none!important;
        line-height: 45px;
    }
    .teamchatpage .teamchat-left .dropdown button.btn.dropdown-toggle:hover, .teamchatpage .teamchat-left .dropdown button.btn.dropdown-toggle:focus{
        background: none !important;
        border: none!important;
        outline: none!important;
        box-shadow: none!important;
    }
    .teamchatpage .teamchat-left .dropdown button.btn.dropdown-toggle i.fa.fa-fw.fa-bars {
        float: right;
        margin-top: 15px;
    }
    .teamchatpage .teamchat-left .dropdown button.btn.dropdown-toggle .first-letter {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        float: left;
        color: #fff;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin-right: 10px;
        justify-content: center;
        font-size: 1.3rem !important;
        background: #7556ff;
    }
    
    .teamchatpage  .custom-accordion a.text-body {
        padding: 12px 0!important;
        border-bottom:1px solid #ddd!important;
        border-top:1px solid #ddd!important;
    }
    .page-content.teamchatpage {
        padding: 0 !important;
        padding-top: calc(50px + 24px) !important;
    }
    
    .page-content.teamchatpage .container-fluid {
        padding: 0 !important;
    }
    
    .page-content.teamchatpage .container-fluid .filemanager-sidebar {
        margin: 0 !important;
    }
    .main-content .full-calendar .container-fluid {
        height: 100vh;
        overflow: hidden;
    }
    
    .main-content .full-calendar .container-fluid .calendar-row.row {
        height: 100%;
    }
    .teamchatpage .teamchat-left .dropdown button.btn.dropdown-toggle i.fa.fa-fw.fa-bars {
        float: right;
        margin-top: 15px;
    }
    
    .dropdown .dropmenu-hd .first-letter {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        float: left;
        color: #fff;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin-right: 10px;
        justify-content: center;
        font-size: 1.3rem !important;
        background: #7556ff;
    }
    
    .dropdown .dropmenu-hd {
        line-height: 40px;
        color: #000;
        font-size: 1rem;
        padding:15px 20px;
    }
    .filemanager-sidebar .categories-list li .custom-accordion ul.list-unstyled {
        padding-left: 0 !important;
    }
    
    .filemanager-sidebar .categories-list li .custom-accordion ul.list-unstyled li a {
        padding: 10px 20px !important;
        font-size: 0.9rem;
    }
    
    .filemanager-sidebar .categories-list li .custom-accordion .card {
        padding-left: 0 !important;
    }
    
    .filemanager-sidebar .categories-list li .custom-accordion a {
        font-size: 0.9rem;
        padding: 12px 20px !important;
    }
    
    .filemanager-sidebar .categories-list li .custom-accordion ul.list-unstyled li a:hover {
        background: rgba(166,149,252, 0.5);
    }
    
    .teamchatpage .filemanager-sidebar {
        padding: 0 !important;
    }
    
    .teamchatpage .filemanager-sidebar .card-body {
        padding: 0 !important;
    }
    .teamchat-section .chat-page .user-chat .chat-head-left {
        width: 70%;
    }
    .teamchat-section .chat-page .user-chat .chat-head-right {
        width: 30%;
    }
    .teamchat-section .chat-page .user-chat .chat-head-left .media-body {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    
    .teamchat-section .chat-page .user-chat .chat-head-left .media-body h5 {
        margin-right: 30px;
    }
    
    .teamchat-section .chat-page .user-chat .chat-head-left .media-body .consing-txt {
        color: #999;
    }
    .teamchat-section .chat-page .user-chat .card .chat-head {
        padding: 0.75rem 2% 0.75rem 2%;
        width: 100%;
        background: #f6f8fb;
    }
    .filemanager-sidebar.ms-lg-2.card .card-body h5.font-size-15.mb-4 {
        font-size: 0.9rem;
        padding: 12px 20px !important;
        border-bottom: 1px solid #ddd;
        text-align:left;
    }
    
    
    .filemanager-sidebar.ms-lg-2.card .card-body {
        margin-top: 48px;
    }
    .teamchatpage .teamchat-left .dropdown .dropdown-menu {
        left: 20% !important;
    }
    .teamchatpage .teamchat-left .dropdown a.dropdown-item {
        font-size: 0.9rem;
        color: #74788d;
    }
    
    .teamchatpage .teamchat-left .dropdown a.dropdown-item i, .teamchatpage .teamchat-left .dropdown a.dropdown-item .icon {
        font-size: 20px;
        margin-right: 10px;
    }
    .teamchatpage .filemanager-sidebar.me-md-2.card {
        background: #fff !important;
        box-shadow: none !important;
    }
    
    .teamchatpage .filemanager-sidebar.me-md-2.card .card-body {
        background: #fff !important;
    }
    .teamchatpage .teamchat-section .chat-conver-outer {
        padding-left: 0;
        margin-right: 0;
        width: 70%;
    }
    
    .teamchatpage .teamchat-section .teamchat-right {
        width: 30%;
    }
    .teamchat-sect {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .teamchatpage .teamchat-section .teamchat-right .tmcht-hd {
        float: left;
        width: 100%;
        border-bottom: 1px solid #ddd;
    }
    
    
    .teamchatpage .teamchat-section .teamchat-right .tmcht-hd h2 {
        font-size: 1rem;
        padding: 12px 20px !important;
        margin: 0;
    }
    ul.chat-head-ul li{
        margin-right:10px;
    }
    ul.chat-head-ul li .call-btn {
        background: #1db954;
        width: 34px;
        height:34px;
        border-radius: 50%;
        display: inline-block;
        line-height: 34px;
        color: #fff;
        font-size: 18px;
    }
    ul.chat-head-ul li .call-btn:hover{
        background: #666;
        color: #fff;
    }
    ul.chat-head-ul li .call-btn i {
        color: #fff;
        font-size: 18px;
    }
    ul.chat-head-ul li .video-btn  {
        color: #fff !important;
        border-color: #afa0f1 !important;
        background: #7d61fc !important;
        background: linear-gradient(320deg, #7d61fc 0%, #afa0f1 100%) !important;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: inline-block;
        line-height: 34px;
        color: #fff;
        font-size: 18px;
    }
    ul.chat-head-ul li .video-btn:hover{
        color: #fff !important;
        border-color: #afa0f1 !important;
        background: #afa0f1 !important;
        background: linear-gradient(
    320deg
    , #afa0f1 0%, #7d61fc 100%) !important;
    }
    ul.chat-head-ul li .video-btn i {
        color: #fff;
        font-size: 18px;
    }
    ul.chat-head-ul li .account-btn {
        background: #ddd;
        width:34px;
        height: 34px;
        border-radius: 50%;
        display: inline-block;
        line-height: 34px;
        color: #666;
        font-size:18px;
    }
    ul.chat-head-ul li .account-btn:hover{
        background: #666;
        color: #fff;
    }
    ul.chat-head-ul li .account-btn i {
        color: #fff;
        font-size: 18px;
    }
    ul.chat-head-ul li .pin-btn{
        background: #ddd;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: inline-block;
        line-height: 34px;
        color: #666;
        font-size: 18px;
    }
    ul.chat-head-ul li .pin-btn:hover{
        background: #666;
        color: #fff;
    }
    ul.chat-head-ul li .pin-btn i {
        color: #fff;
        font-size: 18px;
    }
    .teamchat-section .chat-page .user-chat .chat-head-right ul.chat-head-ul {
        float: left;
        width: auto;
    }
    
    .teamchat-section .chat-page .user-chat .chat-head-right form.app-search.d-none {
        margin-left: 2%;
        width: 52%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0;
    }
    
    .teamchat-section .chat-page .user-chat .chat-head-right form.app-search.d-none .position-relative {
        float: left;
        width: 80%;
    }
    
    .teamchat-section .chat-page .user-chat .chat-head-right form.app-search.d-none .app-search-icon {
        float: right;
        width: 14%;
    }
    
    .teamchat-section .chat-page .user-chat .chat-head-right form.app-search.d-none .app-search-icon a.app-search-icon-btn {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: #f1f5f9;
        display: inline-block;
        text-align: center;
        line-height: 34px;
        color: #c1c6d0;
    }
    
    .teamchat-section .chat-page .user-chat .chat-head-right form.app-search.d-none .position-relative input.form-control {
        background: #f1f5f9 !important;
        border: 1px solid #c1c6d0;
        height: 32px;
        font-size: 15px;
        color: #c1c6d0;
    }
    
    .teamchat-section .chat-page .user-chat .chat-head-right form.app-search.d-none .position-relative span.bx.bx-search {
        font-size: 19px;
        top: -1px;
        color: #9ba1ba;
    }
    
    .teamchat-section .chat-page .user-chat .chat-head-right form.app-search.d-none .position-relative input.form-control::placeholder {
        color: #c1c6d0 !important;
    }
    #layout-wrapper .main-content .page-content.teamchatpage .d-md-flex .card-body {
        padding: 0 !important;
    }
    .teamchatpage .teamchat-section .teamchat-right .chat-left-w.col {
        width: 55% !important;
        flex: auto;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .chat-bottom-icons.col {
        width: 35%;
        flex: inherit;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .chat-bottom-icons.col .chat-input-links li a {
        font-size: 15px;
        color: #7a8095;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .chat-bottom-icons.col .chat-input-links li {
        margin-right: 0.3rem;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .chat-left-w.col .position-relative .form-control.chat-input {
        padding: 0.47rem 0.75rem;
        border: none !important;
        background: none !important;
    }
    
    .teamchatpage .teamchat-section .teamchat-right  .chat-bottom-row.row {
        padding: 0;
        margin-top: 15px;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .chat-bottom-row.row .col-auto.col {
        float: right;
        flex: none !important;
        margin-left: auto;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .chat-bottom-row.row .col-auto.col .btn-rounded.chat-send {
        background: none !important;
        border: none !important;
        width: auto;
        min-width: inherit;
        color: #7556ff !important;
        font-size: 22px;
        padding: 0 !important;
    }
    .teamchat-section .chat-page .teamchat-sect .chat-conver-outer {
        background: #f6f8fb !important;
        height: auto;
    }
    .teamchat-section .chat-page .teamchat-sect .chat-conver-outer .chat-input-section {
        padding: 0 !important;
        margin: 10px 2% !important;
        width: 96%;
        box-shadow: none !important;
        border: none;
    }
    .teamchat-section .chat-page .teamchat-sect .chat-conver-outer .chat-input-section .chat-input-section-outer {
        box-shadow: none !important;
        border: 2px solid #ddd !important;
        border-radius: 8px;
    }
    .teamchat-sect .chat-input-section-outer {
        padding: 0;
        box-shadow: none;
        border: none !important;
    }
    
    .teamchat-sect .chat-input-section-outer .chat-left-bottomrow.row {
        border: 1px solid #ddd;
        padding: 10px 0;
        margin: 0;
        border-radius: 4px;
    }
    
    .teamchat-sect  .p-3.chat-input-section {
        padding: 0 !important;
        margin: 20px 3% 0 3%;
        width: 94%;
        display: inline-block;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .tmcht-content {
        padding: 0 !important;
        margin: 30px 5% 30px 5%;
        width: 90%;
        display: inline-block;
        border-bottom: 1px solid #ddd;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .tmcht-content .tmcht-cnt-date {
        margin: 0 auto 20px auto;
        display: inline-block;
        text-align: center;
        width: 100%;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .tmcht-content .tmcht-cnt-date p {
        margin: 0;
        border: 1px solid #ddd;
        display: inline-block;
        padding: 5px 15px;
        border-radius: 25px;
    }
    .chat-page .user-chat .card {
        box-shadow: none !important;
    }
    .tmcht-cnt-block {
        width: 100%;
        margin: 20px 0;
        display: inline-block;
        border: 1px solid #ddd;
        padding: 15px;
        position: relative;
        border-left: 3px solid #34c2ff;
    }
    
    .tmcht-cnt-dtl {
        width: 90%;
        margin: 0 auto 20px auto;
    }
    
    .tmcht-cnt-dtl .cnt-hd {
        display: inline-block;
        width: 100%;
    }
    .teamchat-section .chat-page .teamchat-sect .teamchat-type.row {
        padding: 18px 15px;
    }
    
    .teamchat-section .chat-page .teamchat-sect .teamchat-type.row .chat-bottom-icons ul li.list-inline-item a {
        color: #444;
        font-size: 20px;
    }
    .tmcht-cnt-dtl .cnt-hd .cnt-hd-grey {
        background: #eee;
        padding: 5px 10px;
        margin: 0 5px;
    }
    
    .tmcht-cnt-dtl .cnt-hd .time {
        color: #b5b5b5;
    }
    
    .tmcht-cnt-block .cnt-blcktwoside {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 0 5px 0;
    }
    
    .tmcht-cnt-block .cnt-blcktwoside .leftside {
        width: 48%;
        float: left;
    }
    
    .tmcht-cnt-block .cnt-blcktwoside .rightside {
        float: right;
        width: 48%;
    }
    
    .tmcht-cnt-block .cnt-box {
        display: flex;
        width: 100%;
        border: 1px solid #ddd;
        flex-wrap: wrap;
        align-items: center;
        margin:20px 0 30px 0;
    }
    
    .tmcht-cnt-block .cnt-box  .leftside {
        width: 50%;
        float: left;
        padding: 15px;
        text-align: center;
    }
    
    .tmcht-cnt-block .cnt-box .rightside {
        float: right;
        width: 50%;
        padding: 15px;
        border-left: 1px solid #ddd;
        margin: 0;
    }
    
    .tmcht-cnt-block .cnt-box .leftside i {
        color: #a056ff;
        font-size: 25px;
    }
    
    .tmcht-cnt-block .cnt-box .rightside span {
        color: #999;
        display: block;
    }
    .tmcht-cnt-block p a {
        color: #5a93ff !important;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .tmcht-content .tmcht-cnt-dtl::after, .teamchatpage .teamchat-section .teamchat-right .tmcht-bottom-name::after {
        font-family: 'boxicons' !important;
        font-weight: normal;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        line-height: 1;
        display: inline-block;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 0.6rem;
        color: #fff;
        content: "\e9da";
        position: absolute;
        left: -20px;
        background: #00ba66;
        width: 12px;
        height: 12px;
        top: 20px;
        line-height: 12px;
        border-radius: 50%;
    }
    .teamchatpage .teamchat-section .teamchat-right .tmcht-content .tmcht-cnt-dtl {
        position: relative;
    }
    
    .teamchatpage .teamchat-section .teamchat-right .tmcht-bottom{
        padding:20px !important;
        margin: 30px 5% 30px 5%;
        width: 90%;
        display: inline-block;
        border-bottom: none;
    }
    .teamchatpage .teamchat-section .teamchat-right .tmcht-bottom-name{
        position: relative;
        margin-bottom: 15px;
    } 
    .teamchatpage .teamchat-section .teamchat-right .tmcht-bottom-name p, .teamchatpage .teamchat-section .teamchat-right .tmcht-bottom-name span{
        margin: 0;
    }
    .teamchatpage .teamchat-section .teamchat-right .tmcht-bottom-name span a{
        color: #5a93ff !important;
    }
    .tmcht-comticon {
        float: left;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    
    .tmcht-comticon .comticon-block {
        background: #f5f5f5;
        padding: 5px 15px;
        float: left;
        margin: 0 15px 0 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 4px;
    }
    
    .tmcht-comticon .comticon-block i {
        font-size: 18px;
        margin-right: 10px;
    }
    .teamchat-section button.btn.btn-primary {
        margin-left: 30px;
    }
    .modal .modal-dialog.teamvideo-popup {
        max-width: 360px !important;
    }
    
    
    .modal .modal-dialog.teamvideo-popup .modal-content button.close {
        display: inline-block !important;
        opacity: 0.8;
        width: 1.2em;
        height: 1.2em;
    }
    
    .modal .modal-dialog.teamvideo-popup .modal-content button.close span {
        font-weight: bold !important;
        opacity: 1 !important;
        font-size: 22px;
    }
    .modal .modal-dialog.teamvideo-popup .modal-header h5.modal-title span.popup-head {
        color: #444;
    }
    .modal .modal-dialog.teamvideo-popup .modal-header{
        border-bottom:none !important;
    }
    .modal .modal-dialog.teamvideo-popup .search-field {
        float: left;
        width: 100%;
        margin: 0 0 20px 0;
    }
    
    .modal .modal-dialog.teamvideo-popup .selection {
        float: left;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 15px 0;
    }
    
    .teamvideo-popup .selectlist-out {
        height: 330px;
        background: #f3f5f7;
        float: left;
        width: 100%;
        overflow: hidden;
        margin-bottom:15px;
    }
    
    .modal .modal-dialog.teamvideo-popup .selection p {
        margin: 0;
    }
    
    .modal .modal-dialog.teamvideo-popup .selection .search-fiter {
        background: #d0d3d6;
        padding: 5px 10px;
        border-radius: 8px;
        color: #fff;
    }
    
    .teamvideo-popup .selectlist-out .selectlist {
        float: left;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        padding: 7px 10px;
        border-bottom: 1px solid #d9dee1;
    }
    
    
    .teamvideo-popup .selectlist-out .selectlist .selectimg {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        background: #888;
        float: left;
        margin-right: 10px;
        position:relative;
    }
    
    .teamvideo-popup .selectlist-out .selectlist .selectimg::after {
        font-family: 'boxicons' !important;
        font-weight: normal;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        line-height: 1;
        display: inline-block;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 0.6rem;
        color: #fff;
        content: "\e9da";
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: #00ba66;
        width: 10px;
        height: 10px;
        bottom: -5px;
        line-height: 10px;
        border-radius: 50%;
    }
    
    .teamvideo-popup .selectlist-out .selectlist .selectname {
        float: left;
        line-height: 32px;
    }
    
    .teamvideo-popup .selectlist-out .selectlist .form-check-input {
        float: right !important;
        margin-top: 10px;
    }
    .teamvideo-popup .selectlist-out .selectlist .form-check-input{
        background: #f3f5f7;
    }
    .teamvideo-popup .selectlist-out .selectlist .form-check-input:checked {
        background-color: #1db954;
        border-color: #1db954;
        position:relative;
    }
    .teamvideo-popup .selectlist-out .selectlist .form-check-input:checked::after{
        font-family: 'boxicons' !important;
        font-weight: normal;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        line-height: 1;
        display: inline-block;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 0.6rem;
        color: #fff;
        content: "\e9da";
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 10px;
        height: 10px;
        top: 1px;
        line-height: 10px;
        border-radius: 50%;
    }
    .modal .modal-dialog.teamvideo-popup .btn.btn-primary{
        margin:0 auto;
        display: block;
    }
    .app-main {
        flex: 1;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #3e3e40;
    }
    .video-call-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    .video-participant {
        width: 24.2%;
        height: 50%;
        position: relative;
        margin: 0.4%;
    }
    .participant-actions {
        position: absolute;
        display: flex;
        left: 15px;
        bottom: 15px;
    }
    .name-tag {
        position: absolute;
        bottom: 12px;
        right: 12px;
        font-size: 12px;
        color: #fff;
        background-color: rgba(188,194,207,0.5);
        border-radius: 4px;
        padding: 4px 12px;
    }
    .video-participant img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .btn-mute {
    
        margin-right: 4px;
        width: 32px;
        height: 32px;
        font-size: 1.4rem;
        color: #fff;
        border: none;
        background: transparent;
    }
    .btn-camera {
      
        width: 32px;
        height: 32px;
        font-size: 1.4rem;
        color: #fff;
        border: none;
        background: transparent;
    }
    .btn-camera:hover, .btn-mute:hover {
        color: #999;
    }
     .page-content.conferencepage {
         background: #3e3e40;
     }
    
    .page-content.conferencepage .container-fluid {
        background: #3e3e40;
    }
    
    .page-content.conferencepage .container-fluid .card {
        background: #3e3e40;
     }
    
     #layout-wrapper .main-content .page-content.conferencepage .container-fluid {
         background: #3e3e40;
     }
    .video-participant.active{
        position: relative;
    }
    .video-participant.active::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 2px solid #2dd099;
    }
    .videoparticipanticon {
        position: absolute;
        top: -30px;
        right: 25px;
        width: 50px;
        height: 50px;
    }
    
    .videoparticipanticon img {
        width: 100%;
        height: 100%;
    }
    .video-call-actions {
        width: 100%;
        background: #1c242a;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    
    .video-call-actions button.video-action-button {
        background: none !important;
        border: none !important;
        color: #fff;
        font-size: 1.6rem;
        padding: 10px 15px;
        display: inline-block;
    }
    .video-call-actions button.video-action-button.call{
        color:#9b2d30;
    }
    .video-call-actions button.video-action-button:hover{
        color: #999 !important;
    }
    .modal-header.popup-searchhead {
        width: 100%;
        padding-bottom: 0 !important;
    }
    
    .modal-header.popup-searchhead .modal-title {
        width: 100%;
        position: relative;
    }
    
    .modal-header.popup-searchhead .modal-title input.form-control {
        border: none !important;
        padding-left: 30px;
        font-size: 1rem;
        font-weight: normal;
        color: #495057;
    }
    
    .modal-header.popup-searchhead .modal-title .bx.bx-search {
        position: absolute;
        top: 9px;
        font-size: 1.2rem;
        color: #6a7187;
    }
    
    .modal-header.popup-searchhead button.close.task-close {
        display: none;
    }
    .modal-body.popup-searchbody .poup-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        border-bottom: 1px solid #ddd;
    }
    .modal-body.popup-searchbody .poup-nav ul.nav-tabs-custom{
         border: none !important;
    }
    .modal-body.popup-searchbody .poup-nav ul.nav-tabs-custom li a.nav-link {
        font-size: 0.9rem;
        font-weight: 400;
        padding: 0.5rem 0.75rem !important;
        border: none !important;
    }
    
    .modal-body.popup-searchbody .poup-nav ul.nav-tabs-custom li.nav-item {
        border: none !important;
    }
    
    .modal-body.popup-searchbody .poup-nav ul.nav-tabs-custom li.nav-item a.active.nav-link {
        color: #fff !important;
        border-color: #afa0f1 !important;
        background: #7d61fc !important;
        background: linear-gradient(320deg, #7d61fc 0%, #afa0f1 100%) !important;
        border-radius: 2rem !important;
    }
    
    .modal-body.popup-searchbody .poup-nav ul.nav-tabs-custom li.nav-item a.active.nav-link::after {
        display: none;
    }
    
    .modal-body.popup-searchbody .poup-nav .poupright-drop {
        float: right;
    }
    
    .modal-body.popup-searchbody .poup-nav .poupright-drop .dropdown {
        background: none !important;
    }
    
    .modal-body.popup-searchbody .poup-nav .poupright-drop .dropdown button.btn.dropdown-toggle {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        font-size: 0.9rem;
        font-weight: 400;
        padding: 0.5rem 0.75rem !important;
    }
    .modal-body.popup-searchbody .poup-nav .poupright-drop .dropdown-menu.show {
        min-width: 8rem;
        right: 0 !important;
        left: auto !important;
        top: 40px !important;
    }
    
    .modal-body.popup-searchbody .poup-nav .poupright-drop .dropdown-menu.show a.dropdown-item {
        padding: 0.35rem 0.75rem;
    }
    .modal-body.popup-searchbody .poup-nav .poupright-drop .dropdown-menu.show a.dropdown-item  i{
        color: #5cbe43;
        float: right;
        font-size: 1.2rem;
    }
    .popup-search {
        float:left;
        width:auto;
        margin-right:20px;
    }
    .popup-searchbody .card-text{
        text-align: center;
        min-height: 350px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        justify-content: center;
    }
    .popup-searchbody .popupsearch-txt{
        text-align: center;
        
    }
    .popup-searchbody .popupsearch-txt p span {
        font-size: 2rem;
        display:inline-block;
    }
    .popup-searchbody .popupsearch-txt p{
        font-size: 1rem;
        display: inline-block;
        width: 100%;
        margin-top: 15px;
    } 
    
    $primary-color: #223562;
    $theme-grey-blue: #7A94AB;
    $theme-green: #148F77;
    .modal-dialog.modal-dialog-centered {
        max-width: 1050px!important;
    }
    
    .modal-dialog.modal-dialog-centered .page-content {
        padding: 0;
    }
    
    .modal-dialog.modal-dialog-centered .page-content .d-lg-flex.chat-page {
        width: 100%;
        display: flex!important;
        flex-direction: inherit !important;
        justify-content: space-between;
        flex-wrap: inherit  !important;
    }
    .modal-dialog.modal-dialog-centered {
        width: 1320px!important;
        max-width: 90% !important;
    }
    
    .chat-page .chat-leftsidebar .create-sect ul li:nth-child(2n) {
        width: 28%;
    }
    .chat-page .chat-leftsidebar .create-sect ul li:first-child {
        width: 43%!important;
    }
    .chat-page .chat-leftsidebar .create-sect ul li:nth-child(3n) {
        width: 25%;
    }
    .chat-page .chat-leftsidebar .create-sect ul li{
        font-size: .75rem!important;
    }
    // .modal-dialog.modal-dialog-centered .modal-body {
    //     padding: 0 !important;
    // }
    
    .modal-dialog.modal-dialog-centered .page-content .container-fluid {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .calendar-timeline-outer {
        float: left;
        width: 100%;
        margin-top:0;
        height: 90vh;
    }
    
    .calendar-timeline-outer .react-calendar-timeline {
        float: left;
        width: 100%;
        height: 100%;
    }
    
    .calendar-timeline-outer .react-calendar-timeline .rct-header-root {
        background: #fff;
        text-align: left;
    }
    
    .calendar-timeline-outer .react-calendar-timeline .rct-header-root .rct-calendar-header {
        border: none !important;
    }
    
    .calendar-timeline-outer .react-calendar-timeline .rct-header-root .rct-calendar-header .rct-dateHeader.rct-dateHeader-primary {
        text-align: left;
        justify-content: left;
        padding-top: 7px;
        padding-bottom: 7px;
    }
    
    .calendar-timeline-outer .react-calendar-timeline .rct-header-root .rct-calendar-header .rct-dateHeader.rct-dateHeader-primary span {
        color: #9c9fb2;
        padding: 7px 15px;
        border: 1px solid #ddd;
        margin: 7px !important;
        border-radius: 4px !important;
    }
    
    .calendar-timeline-outer .react-calendar-timeline .rct-header-root .rct-calendar-header>div {
        height: 50px !important;
    }
    
    .calendar-timeline-outer .react-calendar-timeline .rct-header-root .rct-calendar-header .rct-dateHeader {
        border: none !important;
        background: #fff;
    }
    
    .calendar-timeline-outer .react-calendar-timeline .rct-header-root .rct-calendar-header .rct-dateHeader span {
        font-size: 13px;
        color: #9c9fb2;
    }
    
    .calendar-timeline-outer .react-calendar-timeline .rct-scroll .rct-horizontal-lines .rct-hl-even, .calendar-timeline-outer .react-calendar-timeline .rct-scroll .rct-horizontal-lines .rct-hl-odd {
        border-bottom: none !important;
        background: none !important;
    }
    
    .react-calendar-timeline .rct-outer {
        background: #fbfdff !important;
    }
    .react-calendar-timeline .rct-outer .rct-sidebar {
        border: none !important;
        height: 100% !important;
        min-height: 250px;
    }
    
    .react-calendar-timeline .rct-outer .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even, .react-calendar-timeline .rct-outer .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
        border: none !important;
        background: none !important;
        height: 40px !important;
        line-height: 40px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .chat-conversation .chat-day-title.left {
        background: none;
        float: left;
        width: 100%;
    }
    
    .chat-conversation .chat-day-title.left .title {
        background: #fff;
        float: left;
        width: auto;
        padding: 10px 20px;
        border-radius: 0 25px 25px 0;
        text-align: left;
        color: #48505a;
    }
    
    .chat-conversation .chat-day-title.right {
        background: none;
        float: right;
        width: 100%;
    }
    
    .chat-conversation .chat-day-title.right .title {
        float: right;
        width: auto;
        padding: 10px 20px;
        border-radius: 25px 0 0 25px;
        text-align: left;
        color: #48505a;
        background: #7d61fc!important;
        background: linear-gradient(320deg,#7d61fc,#afa0f1)!important;
        color: #fff;
    }
    .chat-conversation .chat-day-title.left .chat-img {
        float: left;
        margin-right: 15px;
    }
    
    .chat-conversation .chat-day-title::before {
        content: none !important;
    }
    
    .chat-conversation .chat-day-title.left .chat-img img.avatar-xs.rounded-circle {
        height: 2.2rem;
        width: 2.2rem;
    }
    .timeline-row1 .timeline-bar1 {
        border-left: 1px solid #dbdcdd;
        padding-left: 30px;
        padding-right: 30px;
    }
    
    .timeline-row1 .timeline-bar1 .input-group {
        width: 145px !important;
        text-align: center;
        margin-top: 15px;
        
    }
    
    .timeline-row1 .timeline-bar1 .input-group input.form-control {
        background: #fafbfc;
        border:1px solid #a6b6c1;
    }
    .timeline-date .tl-col button.btn.btn-primary {
        background: #e9ebf0 !important;
        border: none !important;
        border-radius: 3px !important;
        color: #6a7480 !important;
        padding: 0 4px !important;
        float: left;
        font-size: 20px;
        line-height: 38px;
        height: 38px;
    }
    
    .timeline-date .tl-col button.btn.btn-primary:hover {
        color: #fff !important;
        background: #afa0f1 !important;
        background: linear-gradient(320deg, #afa0f1 0%, #7d61fc 100%) !important;
    }
    .timeline-row2 .col-md-10.timeline-date {
        padding-left: 7px;
        padding-right: 7px;
    }
    .timeline-row3 .col-md-10.time-bar {
        border-left: 1px solid #dbdcdd;
    }
    
    .timeline-row2 .col-md-10 {
        border-left: 1px solid #dbdcdd;
    }
    .timeline-row1 .col-md-2, .timeline-row2 .col-md-2, .timeline-row1 .col-md-2, .timeline-row3 .col-md-2 {
        background: #fff;
    }
    
    .timeline-row1 .col-md-2 h3 {
        padding :15px 15px 15px 30px;
        margin: 0;
        border-top: none;
        border-bottom: 1px solid #dadfe2;
        position: relative;
        z-index: 2;
    }
    .projectpage .card .card-body .tab-content .tab-pane.timeline-sect {
        padding-left: 0;
        padding-right: 0;
    }
    .projectpage .card .card-body .tab-content .tab-pane.timeline-sect {
        padding-left: 0;
        padding-right: 0;
    }
    
    .projectpage .card .card-body .tab-content .tab-pane.timeline-sect .timeline-row1 .col-md-2 {
        padding: 0 ;
        border-top: none;
        border-bottom: 1px solid #dadfe2;
    }
    .shedule-list {
        border-top:none;
        border-bottom: 1px solid #dadfe2;
        padding : 15px 15px 15px 30px;
    }
    .projectpage .card .card-body .tab-content .tab-pane.timeline-sect .shedule-list .btn.btn-primary {
        background: none !important;
        border: none !important;
        color: #9e9093 !important;
        font-size: 1.1rem;
        padding-left:0;
        margin-right:15px;
        margin-top:5px;
        position:relative;
        z-index:1;
    }
    .timeline-row2 .col-md-10.timeline-date span.tl-col:last-child .btn.btn-primary {
        float: right;
        margin-right: 15px;
    }
    .shedule-list .btn.btn-primary .green-text{
        color: #5cbf44 !important;
    }
    .shedule-list .btn.btn-primary .blue-text{
        color: #7556ff !important;
    }
    .projectpage .card .card-body .tab-content .tab-pane.timeline-sect .shedule-list .btn.btn-primary:hover, .projectpage .card .card-body .tab-content .tab-pane.timeline-sect .shedule-list .btn.btn-primary:focus, .projectpage .card .card-body .tab-content .tab-pane.timeline-sect .shedule-list .btn.btn-primary:active{
        color: #000!important;
    }
    .timeline-row3 ul.tl-milestore li{
        list-style:none;
    }
    .timeline-row3 ul.tl-milestore li a {
        position: relative;
        padding-left: 20px;
        color: #3d444b;
        font-size: 1rem;
        font-weight: 500;
        text-align: left;
    }
    .timeline-row3 ul.tl-milestore.closed li a::before{
        font-family: 'boxicons' !important;
        font-weight: normal;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        line-height: 1;
        display: inline-block;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\eafd"!important;
        position:absolute;
        width:18px;
        height:18px;
        color: #dadfe2;
        left:-10px;
        border:1px solid #dadfe2;
        background:#fff;
        border-radius:2px;
        top:3px;
    }
    .timeline-row3 ul.tl-milestore.opened li a::before{
        font-family: 'boxicons' !important;
        font-weight: normal;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        line-height: 1;
        display: inline-block;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\eace"!important;
        position:absolute;
        width:18px;
        height:18px;
        color: #dadfe2;
        left:-10px;
        border:1px solid #dadfe2;
        background:#fff;
        border-radius:2px;
        top:3px;
    }
    .timeline-row3 ul.tl-milestore {
        padding-left: 30px;
        position: relative;
        overflow: hidden;
    }
    .timeline-row3 ul.tl-milestore li .collapse ul {
        padding: 0;
    }
    .timeline-row3 ul.tl-milestore::after {
        width: 1px;
        height: 100%;
        position: absolute;
        content: "";
        background: #dadfe2;
        top: 24px;
        left: 28px;
    }
    .timeline-row3 ul.tl-milestore li .collapse ul li {
        position: relative;
        padding-left: 20px;
        padding: 7px 0 7px 20px;
    }
    
    .timeline-row3 ul.tl-milestore li .collapse ul li::before {
        width: 15px;
        height: 1px;
        background: #dadfe2;
        content: "";
        position: absolute;
        left: 0;
        top: 17px;
    }
    
    .timeline-row2.row {
        position: relative;
        margin-top: -60px;
    }
    
    .timeline-row2 .col-md-2 {
        background: transparent;
    }
    
    .timeline-row3 .col-md-2 {
        padding-top: 22px;
    }
    .timeline-row2 .col-md-2 {
    
        border-bottom: 1px solid #ddd;
    }
    .col-md-10.timeline-date {
        border-bottom: 1px solid #ddd;
        padding-bottom: 8px;
    }
    .timeline-date .tl-col{
        position:relative;
    }
    .timeline-date .tl-col::after {
        height: 100vh;
        width: 1px;
        position: absolute;
        right: 0;
        background: #dbdcdd;
        content: "";
        top:0;
    }
    .tab-pane.timeline-sect {
        min-height: 80vh;
        overflow: hidden;
    }
    .shedule-list::after {
        height: 100vh;
        width: 1px;
        position: absolute;
        right: 0;
        background: #dbdcdd;
        content: "";
        top:0;
    }
    .shedule-list::before {
        height: 100vh;
        width: 100%;
        position: absolute;
        right: 0;
        background: #fff;
        content: "";
        top: 0;
    }
    .projectpage.project-taskpage .project-head{
        margin-top:25px;
    }
    .wide-modalsize .compose-page {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        overflow: hidden;
        height: 100%;
    }
    .wide-modalsize .compose-page .compose-leftsidebar {
        float: left;
        width: 18%;
        background: #fff;
        position: relative;
    }
    .wide-modalsize .compose-page .user-compose {
        float: right;
        width: 82%;
        border-left: 1px solid #eff0f5;
    }
    .compose-page .user-compose .card {
        position: relative;
        float: left;
        width: 100%;
        margin: 0 !important;
        height: 100%;
       box-shadow: none !important;
    }
    .compose-page .user-compose .card .compose-head {
        border-bottom: 1px solid #dbdee8 !important;
        padding: 1rem 4% 1rem 4%;
        position: sticky;
        left: auto;
        width: 100%;
        background: #fff;
        z-index: 999;
    }
    .compose-page .compose-conver-outer {
        background: #fff;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0 !important;
        height: 100%;
        position: relative;
    }
    .compose-page .compose-head .media {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .compose-page .compose-head .media .compose-head-left {
        width: 30%;
        display: flex;
    }
    .compose-page .compose-head .media .compose-head-right {
        float: right;
    }
    .compose-page .compose-conver-outer .compose-conversation.p-3 {
        padding-left: 0 !important;
        padding-right: 0 !important;
        height: 100%;
        max-height: 67vh;
        overflow-y: auto;
    }
    .compose-link{
        width: 100%;
        text-align:center;
    }
    
    .compose-link a {
        color: #fff !important;
        border-color: #afa0f1 !important;
        background: #7d61fc !important;
        background: linear-gradient( 
    320deg
     , #7d61fc 0%, #afa0f1 100%) !important;
        border-radius: 2rem !important;
        padding: 0.47rem 1.25rem;
    }
    .compose-link a:hover{
        color: #fff !important;
        border-color: #afa0f1 !important;
        background: #afa0f1 !important;
        background: linear-gradient(
    320deg
    , #afa0f1 0%, #7d61fc 100%) !important;
        border-radius: 2rem !important;
    } 
    .compose-leftsidebar-first {
        padding-top: 50px;
        float: left;
        width: 100%;
    }
    
    .compose-leftsidebar-first .compose-menu {
        float: left;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .compose-leftsidebar-first .compose-menu .compose-link {
        margin-bottom: 30px;
    }
    
    .compose-leftsidebar-first .compose-menu .compose-link a {
        display: inline-block;
        width: 100%;
    }
    
    .compose-leftsidebar-first .compose-menu ul {
        padding: 0;
        float: left;
        width: 100%;
    }
    
    .compose-leftsidebar-first .compose-menu ul li {
        list-style: none;
        margin-bottom: 5px;
    }
    
    .compose-leftsidebar-first .compose-menu ul li a {
        padding: 10px 0;
        float: left;
        width: 100%;
        color: #656b71;
        font-size: 0.9rem;
    }
    
    .compose-leftsidebar-first .compose-menu ul li a i {
        margin-right: 10px;
    }
    
    .compose-leftsidebar-first .compose-menu ul li a:hover {
        color: #7d5fff;
    }
    .compose-left-btm {
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(123,92,253,0.2);
        width: 100%;
    }
    
    .compose-left-btm a {
        height: 40px;
        text-align: center;
        line-height: 40px;
        display: inline-block;
        width: 100%;
        color: #7556ff;
        padding: 0;
    }
    
    .compose-left-btm a:hover {
        color: #000;
    }
    .compose-page .compose-head .media .compose-head-right ul.compose-head-ul {
        display: flex;
        margin: 7px 0 0 0;
        flex-wrap: wrap;
    }
    
    .compose-page .compose-head .media .compose-head-right ul.compose-head-ul li {
        padding: 0;
        list-style: none;
        display: inline-block;
        margin-left: 15px;
    }
    .compose-all.table tr {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border: none !important;
    }
    
    .compose-all.table tr td {
        border: none !important;
    }
    .compose-page .compose-head .media .compose-head-right ul.compose-head-ul li:last-child a {
        color: #fff !important;
        border-color: #afa0f1 !important;
        background: #7d61fc !important;
        background: linear-gradient(320deg, #7d61fc 0%, #afa0f1 100%) !important;
        border-radius: 2rem !important;
        padding: 0.3rem 1rem;
    }
    
    .compose-page .compose-head .media .compose-head-right ul.compose-head-ul li:last-child a i {
        margin-left: 5px;
    }
    
    .compose-page .compose-head .media .compose-head-right ul.compose-head-ul li:last-child a:hover {
        color: #fff !important;
        border-color: #afa0f1 !important;
        background: #afa0f1 !important;
        background: linear-gradient(320deg, #afa0f1 0%, #7d61fc 100%) !important;
    }
    .compose-page .compose-conver-outer ul.nav.nav-tabs {
        position: absolute;
        top: -37px;
        z-index: 999;
        border: none !important;
    }
    
    .compose-page .compose-conver-outer ul.nav.nav-tabs li.nav-item {
        border: none !important;
        box-shadow: none !important;
    }
    
    .compose-page .compose-conver-outer ul.nav.nav-tabs li.nav-item a.nav-link {
        border: none !important;
        box-shadow: none !important;
        position: relative;
    }
    
    .compose-page .compose-conver-outer ul.nav.nav-tabs li.nav-item a.active.nav-link::after {content: "";position: absolute;left: 0;width: 100%;background: #46c9e6;height: 2px;bottom: 0;}
    .compose-page .compose-head .media .compose-head-right ul.compose-head-ul .search-icon {
        font-size: 1.2rem;
        color: #6c7177;
    }
    .compose-all.table{
        width:85%;
        padding-top:0 ;
        padding-bottom:0 ;
    }
    .compose-all.table .letter-img.green{
        width:35px;
        height:35px;
        line-height:35px;
        color:#fff;
        text-transform: uppercase;
        font-size:1.1rem;
        border-radius:50%;
        text-align: center;
        background:#56b644;
        display:inline-block;
        overflow:hidden;
        font-weight:normal;
    }
    .compose-all.table .letter-img.blue{
        width:35px;
        height: 35px;
        line-height:35px;
        color:#fff;
        text-transform: uppercase;
        font-size:1.1rem;
        border-radius:50%;
        text-align: center;
        background:#7164e9;
        display:inline-block;
        overflow:hidden;
        font-weight:normal;
    }
    // modal width
    .modal-dialog.modal-dialog-centered.sml-table {
        width: 500px!important;
        max-width: 75%!important;
    }
    .modal-dialog.modal-dialog-centered.mdm-table {
        width: 650px!important;
        max-width: 80%!important;
    }
    .modal-dialog.modal-dialog-centered.big-table {
        width: 800px!important;
        max-width: 90%!important;
    }
    .modal-dialog.modal-dialog-centered.sml-table .modal-content {
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        flex-direction: inherit !important;
    }
    // 
    .modal-dialog.modal-dialog-centered .modal-content .css-2b097c-container{
        width: 100%;
        margin: 0 0 15px 0;
        padding: 10px 15px;
        border-radius: 8px !important;
        border: 1px solid #dadada;
        font-size: 0.8rem;
        color: #aeaeae;
    }
    .add-filter ul.add-filter-ul{
        float: right;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        padding: 0 1rem 0 0;
      
      }
    .add-filter ul.add-filter-ul li.grey-bg {
        display: inline-block;
        font-size: 13px;
        padding: 3px 15px;
        border-radius: 6px;
        margin-left: 20px;
        border: 1px solid #e9ebf0;
        color: #898c90;
        background: #e9ebf0;
        list-style: none;
    }
    
    .add-firstrow {
        border-bottom: 1px solid #eff1f4;
        align-items: center;
    }
    .add-firstrow .add-srh {
        text-align: left;
        border-right: 1px solid #eff1f4;
        margin-left: 0;
    margin-right: 0;
    }
    
    .add-firstrow .add-srh form.app-search {
        float: left !important;
        margin-left: 0.25 !important;
        padding: 0;
        float: left;
        width: 100%;
    }
    
    .add-firstrow .add-srh form.app-search .position-relative {
        float: left;
        width: 100%;
    }
    
    .add-firstrow .add-srh form.app-search .position-relative input.form-control {
        border: none !important;
        background: transparent !important;
        height: 36px;
        font-size: 14px;
    }
    
    .add-firstrow .add-srh form.app-search .position-relative .bx.bx-search {
        font-size: 18px;
        top: 4px;
        color: #666;
        height: auto !important;
        line-height: 30px;
    }
    .projectpage.addnewpage .card .card-body .tab-content {
        background: #fff;
        padding: 0 !important;
    }
    
    .projectpage.addnewpage .card .card-body .tab-content .tab-pane {
        padding-left: 0;
        padding-right: 0;
    }


    .addnew-leftside {
        border-right: 1px solid #eff1f4;
        padding: 0;
  
    }
    
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar {
        float: left;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 0 !important;
        display: inline-block !important;
    }
    
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar .allfiles-nav {
        float: left;
        width: 100%;
        border-bottom:1px solid #eff1f4;
        margin-bottom:20px;
    }
    
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar .allfiles-nav a.allfile {
        float: left;
        text-align: left;
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        color: #555;
        font-weight: 500;
    }
    
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar .allfiles-nav a.allfile i {
        text-align: right;
        float: right;
    }
    
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar  .dropdown {
        float: left;
        width: 100%;
        background: none !important;
        padding-left:1rem;
    }
    
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar .dropdown button.btn.btn-light.dropdown-toggle {
        color: #fff !important;
        border-color: #afa0f1 !important;
        background: #7d61fc !important;
        background: linear-gradient(320deg, #7d61fc 0%, #afa0f1 100%) !important;
        border-radius: 2rem !important;
        float: left;
        width: auto !important;
        padding: 0.5rem 2rem !important;
    }
    
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar .dropdown button.btn.btn-light.dropdown-toggle:hover {
        color: #fff !important;
        border-color: #afa0f1 !important;
        background: #afa0f1 !important;
        background: linear-gradient(320deg, #afa0f1 0%, #7d61fc 100%) !important;
        border-radius: 2rem !important;
    }
    
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar ul.addleft-sub {
        float: left;
        width: 100%;
        margin: 30px 0 0 0;
        padding: 0;
    }
    .addnewpage  .tab-content .add-secondrow.row{
        margin-left:0;
        margin-right:0;
    }
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar ul.addleft-sub li {
        float: left;
        width: 100%;
        list-style: none;
    }
    
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar ul.addleft-sub li a {
        float: left;
        width: 100%;
        color: #9ba1bb;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        background: #fff;
    }
    
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar ul.addleft-sub li a:hover {
        color: #7556ff;
          font-size: 0.9rem;
        background: rgba(117,86,255,0.2);
    }
      
    #layout-wrapper .main-content .addnew-leftside .filemanager-sidebar ul.addleft-sub li a.active {
        color: #7556ff;
          font-size: 0.9rem;
        background: rgba(117,86,255,0.2);
        font-weight:500;
    }
    
    .addnewpage  .tab-content .add-secondrow .addnew-rightside.col-lg-10{
        padding-left:2rem;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    @media screen and (max-width: 1200px) {
    .rowone.row .col-md-6,
    .rowtwo.row .col-md-6 {
        width: 100%;
    }
    }
    
    @media screen and (min-width:768px) and (max-width:990px) {
    #page-topbar .navbar-header ul.topmenu li a {
        padding: 10px 6px;
        font-size: 0.75rem;
        margin-left: 0 !important;
    }
    form.app-search.d-none.d-lg-block {
        margin-left: 0;
        width: 20%;
    }
    #layout-wrapper .main-content .page-content .container-fluid {
        padding: 30px 15px;
    }
    #layout-wrapper .main-content .page-content .card .card-body .h4.card-title {
        padding: 15px 0;
    }
    #layout-wrapper .main-content .page-content .container-fluid {
        padding: 30px 15px;
    }
    .myevent-card.card .card-body .add-event .rightbar-title {
        padding: 12px 0 !important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .row .col-md-6 {
        width: 50%;
    }
    .myevent-card.card .card-body .add-event .p-4.meet-block {
        padding: 1rem 0 !important;
    }
    .myevent-card.card .card-body .add-event {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .header-profile-user {
        height: 35px!important;
        width: 35px!important;
    }
    button#page-header-user-dropdown {
        padding: 0 !important;
    }
    #page-topbar .navbar-header ul.topmenu {
        margin-left: 0!important;
    }
    form.app-search.d-none.d-lg-block {
        margin-left: 0!important;
        width: 18%!important;
    }
    form.app-search.d-none.d-lg-block input.form-control {
        height: 36px!important;
    }
    form.app-search .position-relative span.bx.bx-search {
        font-size: 16px !important;
        top: -1px !important;
    }
    .navbar-header .d-flex:nth-child(1) {
        width: 88%!important;
    }
    form.app-search.d-none.d-lg-block input.form-control {
        height: 34px !important;
        font-size: 13px !important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group {
        margin-top: 0 !important;
    }
    
    
    .loginpage .form-head a.logo-form {
        margin: 0 0 5px;
    }
    
    .loginpage .form-head a.logo-form img {
        width: 25px;
        height: 25px;
    }
    
    .loginpage .form-head .h2.card-title {
        font-size: 1.4rem;
        margin: 0 0 7px;
    }
    
    .loginpage .form-head p {
        font-size: 0.5rem;
    }
    
    .loginpage .form-feilds .gogle-sect {
        margin: 7px 0;
    }
    
    .loginpage .form-feilds .gogle-sect a.googlelink {
        line-height: 32px;
        height: 32px;
        font-size: 0.6rem;
        margin: 0 0 6px;
    }
    
    .loginpage .loginform .form-feilds .mb-3 {
        margin-bottom: 7px!important;
    }
    
    .loginpage .loginform .form-feilds .form-group label {
        font-size: 0.5rem;
    }
    
    .loginpage .loginform .form-feilds .form-group input.form-control {
        height: 30px;
        padding: 0 15px;
        line-height: 30px;
        font-size: 0.7rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check {
        font-size: .5rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check input[type=checkbox] {
        width: 11px;
        height: 11px;
        margin-right: 7px;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt a.text-muted {
        font-size: 0.5rem;
    }
    
    .loginpage .form-feilds button.btn.btn-primary.btn-block {
        line-height: 30px;
        height: 30px;
        font-size: 0.8rem;
    }
    .loginpage .login-leftside .card .signin-ftr {
        padding: 0.5rem 1.25rem;
    }
    
    .loginpage .login-leftside .card .signin-ftr p {
        font-size: 0.5rem;
    }
    .navbar-header .d-flex a {
        font-size: 0.83rem;
        color: #6a7187;
        padding: 10px 5px;
        margin-left: 5px;
        transition: ease all 0.6s;
    }
    }
    
    @media screen and (min-width:991px) and (max-width:1023px) {
    #page-topbar .navbar-header ul.topmenu li a {
        padding: 10px 6px;
        font-size: 0.75rem;
        margin-left: 0 !important;
    }
    form.app-search.d-none.d-lg-block {
        margin-left: 0;
        width: 20%;
    }
    #layout-wrapper .main-content .page-content .container-fluid {
        padding: 30px 15px;
    }
    #layout-wrapper .main-content .page-content .card .card-body .h4.card-title {
        padding: 15px 0;
    }
    #layout-wrapper .main-content .page-content .container-fluid {
        padding: 30px 15px;
    }
    .myevent-card.card .card-body .add-event .rightbar-title {
        padding: 12px 0 !important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .row .col-md-6 {
        width: 50%;
    }
    .myevent-card.card .card-body .add-event .p-4.meet-block {
        padding: 1rem 0 !important;
    }
    .myevent-card.card .card-body .add-event {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .header-profile-user {
        height: 35px!important;
        width: 35px!important;
    }
    button#page-header-user-dropdown {
        padding: 0 !important;
    }
    #page-topbar .navbar-header ul.topmenu {
        margin-left: 0!important;
    }
    form.app-search.d-none.d-lg-block {
        margin-left: 0!important;
        width: 18%!important;
    }
    form.app-search.d-none.d-lg-block input.form-control {
        height: 36px!important;
    }
    form.app-search .position-relative span.bx.bx-search {
        font-size: 16px !important;
        top: -1px !important;
    }
    .navbar-header .d-flex:nth-child(1) {
        width: 88%!important;
    }
    form.app-search.d-none.d-lg-block input.form-control {
        height: 34px !important;
        font-size: 13px !important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group {
        margin-top: 0 !important;
    }
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .done-color,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .progess-color,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .todo-color,
    .mytask-card.card .tab-content>.tab-pane .complete-color {
        width: 80px!important;
    }
    .chat-page .chat-leftsidebar .breadcrumb-sect ul li {
        padding: 8px 8px 8px 10px;
    }
    .chat-page .chat-leftsidebar .create-sect ul li {
        padding: 15px 3%;
        font-size: 0.75rem !important;
        width: 33%!important;
    }
    .chat-page .chat-leftsidebar .create-sect ul li:nth-child(1) {
        width: 33%!important;
    }
    .chatleft-attach table td:first-child,
    .chatleft-subtask .table-responsive td:first-child {
        width: 30px;
    }
    .chat-page .chatleft-dropfiles span i {
        margin-right: 10px;
    }
    .chat-page .chat-leftsidebar .chatleft-task h2.chat-task-h2,
    .chat-page .chat-leftsidebar .chatleft-task .chat-task-h2.h2,
    .chat-page .chat-leftsidebar .chatleft-subtask h2.chat-task-h2,
    .chat-page .chat-leftsidebar .chatleft-subtask .chat-task-h2.h2,
    .chatleft-attach h2 .chat-task-h2 {
        font-size: 1.4rem;
    }
    .chatleft-subtask .table-responsive td,
    .chatleft-attach td {
        border: none !important;
        font-size: 0.9rem;
        color: #414141;
        padding: 0.5rem 0.5rem;
    }
    .modal .modal-dialog.wide-modalsize.modal-dialog-centered {
        max-width: 94% !important;
    }
    .login-rightside .lg-sidetxt .h2.card-title {
        font-size: 2rem;
    }
    .loginpage .form-head a.logo-form {
        margin: 0 0 7px;
    }
    
    .loginpage .form-head a.logo-form img {
        width: 30px;
        height: 30px;
    }
    
    .loginpage .form-head .h2.card-title {
        font-size: 1.5rem;
        margin: 0 0 10px;
    }
    
    .loginpage .form-head p {
        font-size: 0.6rem;
    }
    
    .loginpage .form-feilds .gogle-sect {
        margin: 10px 0;
    }
    
    .loginpage .form-feilds .gogle-sect a.googlelink {
        line-height: 36px;
        height: 36px;
        font-size: 0.7rem;
        margin: 0 0 7px;
    }
    
    .loginpage .loginform .form-feilds .mb-3 {
        margin-bottom: 10px!important;
    }
    
    .loginpage .loginform .form-feilds .form-group label {
        font-size: 0.6rem;
    }
    
    .loginpage .loginform .form-feilds .form-group input.form-control {
        height: 35px;
        padding: 0 15px;
        line-height: 35px;
        font-size: 0.8rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check {
        font-size: .6rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check input[type=checkbox] {
        width: 12px;
        height: 12px;
        margin-right: 7px;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt a.text-muted {
        font-size: 0.6rem;
    }
    
    .loginpage .form-feilds button.btn.btn-primary.btn-block {
        line-height: 35px;
        height: 35px;
        font-size: 0.9rem;
    }
    .loginpage .login-leftside .card .loginform.card-body {
        width: 500px;
        max-width: 80%;
        top: 45%;
    }
    .loginpage .login-leftside .card .signin-ftr {
        padding: 0.5rem 1.25rem;
    }
    
    .loginpage .login-leftside .card .signin-ftr p {
        font-size: 0.5rem;
    }
    }
    @media screen and (min-width:768px) and (max-width:1023px) {
    .login-rightside .lg-sidetxt {
        width: 84%;
    }
    .login-rightside .lg-sidetxt .h2.card-title {
        font-size: 2rem;
    }
    .loginpage .form-head a.logo-form {
        margin: 0 0 7px;
    }
    
    .loginpage .form-head a.logo-form img {
        width: 30px;
        height: 30px;
    }
    
    .loginpage .form-head .h2.card-title {
        font-size: 1.5rem;
        margin: 0 0 10px;
    }
    
    .loginpage .form-head p {
        font-size: 0.6rem;
    }
    
    .loginpage .form-feilds .gogle-sect {
        margin: 10px 0;
    }
    
    .loginpage .form-feilds .gogle-sect a.googlelink {
        line-height: 36px;
        height: 36px;
        font-size: 0.7rem;
        margin: 0 0 7px;
    }
    
    .loginpage .loginform .form-feilds .mb-3 {
        margin-bottom: 10px!important;
    }
    
    .loginpage .loginform .form-feilds .form-group label {
        font-size: 0.6rem;
    }
    
    .loginpage .loginform .form-feilds .form-group input.form-control {
        height: 35px;
        padding: 0 15px;
        line-height: 35px;
        font-size: 0.8rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check {
        font-size: .6rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check input[type=checkbox] {
        width: 12px;
        height: 12px;
        margin-right: 7px;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt a.text-muted {
        font-size: 0.6rem;
    }
    
    .loginpage .form-feilds button.btn.btn-primary.btn-block {
        line-height: 35px;
        height: 35px;
        font-size: 0.9rem;
    }
    .loginpage .login-leftside .card .loginform.card-body {
        width: 500px;
        max-width: 80%;
        top: 45%;
    }
    .loginpage .login-leftside .card .signin-ftr {
        padding: 0.5rem 1.25rem;
    }
    
    .loginpage .login-leftside .card .signin-ftr p {
        font-size: 0.5rem;
    }
    }
    @media screen and (min-width:1024px) and (max-width:1280px) {
    .chat-page .chat-leftsidebar .chat-leftsidebar-first {
        max-height: 80vh;
    }
    .chat-page .chat-conver-outer .chat-conversation.p-3 {
        max-height: 68vh;
    }
    .loginpage .form-head a.logo-form {
        margin: 0 0 20px;
    }
    .loginpage .form-head a.logo-form img {
        width: 45px;
        height: 45px;
    }
    .loginpage .form-head .h2.card-title {
        margin: 0 0 15px;
        font-size: 2rem;
    }
    .loginpage .form-feilds .gogle-sect {
        margin: 30px 0;
    }
    .loginpage .login-leftside .card .loginform.card-body {
        width: 500px;
        max-width: 90%;
    }
    .navbar-header .d-flex a {
        font-size: 0.78rem !important;
        margin-left: 0 !important;
        padding: 10px 6px !important;
    }
    .navbar-header .d-flex:first-child {
        width: 83.5%!important;
        justify-content: left!important;
    }
    form.app-search.d-none.d-lg-block {
        margin-left: 2%!important;
        width: 15%!important;
    }
    form.app-search .position-relative span.bx.bx-search {
        font-size: 18px;
        top: 1px;
        line-height: 38px !important;
    }
    form.app-search .position-relative input.form-control,
    form.app-search .position-relative input.form-control::placeholder {
        height: 38px !important;
        font-size: 11px;
        line-height: 38px !important;
    }
    .modal .modal-dialog {
        max-width: 550px !important;
    }
    #layout-wrapper .main-content .container-fluid {
        padding: 20px 15px;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .row .col-md-6 {
        width: 50%;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group {
        margin-top: 0 !important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-6:nth-child(1) {
        width: 45%!important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-6:nth-child(2) {
        width: 55%!important;
    }
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table .progress {
        width: 72%!important;
        height: 0.60rem!important;
    }
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table .progress .percentage-txt {
        font-size: 10px!important;
        right: -22px!important;
    }
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tr td,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tr th {
        font-size: 15px!important;
    }
    #layout-wrapper .main-content .page-content .card .card-body ul.nav-tabs-custom.nav-justified.nav.nav-tabs li.nav-item a {
        padding: 0.5rem 0.5rem!important;
        font-size: 15px !important;
    }
    #layout-wrapper .main-content .page-content .card.mytask-card .card-body .tab-content .table-responsive table.table tbody tr td:first-child {
        padding-left: 15px !important;
        width: 49%;
    }
    #layout-wrapper .main-content .page-content .card.mytask-card .card-body .tab-content .table-responsive table.table tbody tr td:first-child {
        padding-left: 15px !important;
    }
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .badge-soft-success.badge.badge-success.badge-pill .done-color,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .badge-soft-success.badge.badge-success.badge-pill .progess-color,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .badge-soft-success.badge.badge-success.badge-pill .todo-color,
    .mytask-card.card .tab-content>.tab-pane .badge-soft-success.badge.badge-success.badge-pill .complete-color {
        width: 80px!important;
        font-size: 10px !important;
    }
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td.sml-text {
        width: 26%;
        padding-left: 10px !important;
        font-size: 12px !important;
    }
    #layout-wrapper .main-content .page-content .card .card-body ul.nav-tabs-custom.nav-justified.nav.nav-tabs li.nav-item a {
        padding: 0.4rem 1rem !important;
        font-size: 13px !important;
    }
    span.popup-form {
        font-size: 13px !important;
    }
    .chat-page .chat-leftsidebar .breadcrumb-sect ul li {
        padding: 8px 8px 8px 10px;
    }
    .chat-page .chat-leftsidebar .create-sect ul li {
        padding: 15px 3%;
        font-size: 0.75rem !important;
        width: 33%!important;
    }
    .chat-page .chat-leftsidebar .create-sect ul li:nth-child(1) {
        width: 33%!important;
    }
    .chatleft-attach table td:first-child,
    .chatleft-subtask .table-responsive td:first-child {
        width: 30px;
    }
    .chat-page .chatleft-dropfiles span i {
        margin-right: 10px;
    }
    .chat-page .chat-leftsidebar .chatleft-task h2.chat-task-h2,
    .chat-page .chat-leftsidebar .chatleft-task .chat-task-h2.h2,
    .chat-page .chat-leftsidebar .chatleft-subtask h2.chat-task-h2,
    .chat-page .chat-leftsidebar .chatleft-subtask .chat-task-h2.h2,
    .chatleft-attach h2 .chat-task-h2 {
        font-size: 1.4rem;
    }
    .chatleft-subtask .table-responsive td,
    .chatleft-attach td {
        border: none !important;
        font-size: 0.9rem;
        color: #414141;
        padding: 0.5rem 0.5rem;
    }
    .modal .modal-dialog.wide-modalsize.modal-dialog-centered {
        max-width: 94% !important;
    }
    .chat-page .chat-leftsidebar .chat-leftsidebar-first {
        max-height: 84vh;
    }
    .chat-page .chat-conver-outer .chat-conversation.p-3 {
        max-height: 74vh;
    }
    .chat-bottom-row.row {
        padding: 20px 0 10px 0;
    }
    
    .loginpage .form-head a.logo-form {
        margin: 0 0 7px;
    }
    
    .loginpage .form-head a.logo-form img {
        width: 30px;
        height: 30px;
    }
    
    .loginpage .form-head .h2.card-title {
        font-size: 1.5rem;
        margin: 0 0 10px;
    }
    
    .loginpage .form-head p {
        font-size: 0.6rem;
    }
    
    .loginpage .form-feilds .gogle-sect {
        margin: 10px 0;
    }
    
    .loginpage .form-feilds .gogle-sect a.googlelink {
        line-height: 36px;
        height: 36px;
        font-size: 0.7rem;
        margin: 0 0 7px;
    }
    
    .loginpage .loginform .form-feilds .mb-3 {
        margin-bottom: 10px!important;
    }
    
    .loginpage .loginform .form-feilds .form-group label {
        font-size: 0.6rem;
    }
    
    .loginpage .loginform .form-feilds .form-group input.form-control {
        height: 35px;
        padding: 0 15px;
        line-height: 35px;
        font-size: 0.8rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check {
        font-size: .6rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check input[type=checkbox] {
        width: 12px;
        height: 12px;
        margin-right: 7px;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt a.text-muted {
        font-size: 0.6rem;
    }
    
    .loginpage .form-feilds button.btn.btn-primary.btn-block {
        line-height: 35px;
        height: 35px;
        font-size: 0.9rem;
    }
    .loginpage .login-leftside .card .loginform.card-body {
        width: 500px;
        max-width: 80%;
        top: 45%;
    }
    .loginpage .login-leftside .card .signin-ftr {
        padding: 0.6rem 1.25rem;
    }
    
    .loginpage .login-leftside .card .signin-ftr p {
        font-size: 0.6rem;
        margin:0 !important;
    }
    }
    
    @media screen and (min-width:1281px) and (max-width:1400px) {
    .navbar-header .d-flex a {
        font-size: 1rem !important;
        margin-left: 0 !important;
        padding: 10px 10px !important;
    }
    .navbar-header .d-flex:first-child {
        width: 85%!important;
        justify-content: left!important;
    }
    form.app-search.d-none.d-lg-block {
        margin-left: 2%!important;
        width: 20%!important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .row .col-md-6 {
        width: 50%;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group {
        margin-top: 0 !important;
    }
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tr td,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tr th {
        font-size: 13px!important;
        padding: 5px 5px!important;
    }
    #layout-wrapper .main-content .page-content .card .card-body ul.nav-tabs-custom.nav-justified.nav.nav-tabs li.nav-item a {
        padding: 0.75rem 0.5rem!important;
        font-size: 13px!important;
    }
    span.Medium-text,
    span.Low-text {
        font-size: 13px!important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group label.btn.btn-primary {
        padding: 3px 5px!important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm,
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 .btn-group-sm>button.btn.btn-primary {
        width: 15px!important;
        height: 23px!important;
    }
    #layout-wrapper .main-content .container-fluid {
        padding: 40px 15px!important;
    }
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr th,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td {
        font-size: 12px!important;
    }
    .avatar-xs {
        height: 1.3rem!important;
        width: 1.3rem!important;
    }
    .avatar-xs .avatar-title.rounded-circle.font-size-16 {
        font-size: 10px !important;
    }
    #layout-wrapper .main-content .container-fluid .rowone.row .col-md-6 .card-body,
    #layout-wrapper .main-content .container-fluid .rowtwo.row .col-md-6 .card-body {
        padding: 1rem 0.75rem!important;
    }
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table td span.font-size-12.badge-soft-success.badge.badge-success.badge-pill,
    .mytask-card.card .tab-content>.tab-pane span.font-size-12.badge-soft-success.badge.badge-success.badge-pill,
    span.font-size-12.badge-soft-success.badge.badge-success.badge-pill,
    span.font-size-12.badge-soft-warning.badge.badge-warning.badge-pill {
        padding: 0.5em 0.5em!important;
        font-size: 12px !important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-6:nth-child(1) {
        width: 45%!important;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-6:nth-child(2) {
        width: 55%!important;
    }
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table .progress {
        width: 72%!important;
        height: 0.60rem!important;
    }
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table .progress .percentage-txt {
        font-size: 10px!important;
        right: -22px!important;
    }
    #layout-wrapper .main-content .page-content .card.mytask-card .card-body .tab-content .table-responsive table.table tbody tr td:first-child {
        padding-left: 15px !important;
        width: 49%;
    }
    #layout-wrapper .main-content .page-content .card.mytask-card .card-body .tab-content .table-responsive table.table tbody tr td:first-child {
        padding-left: 15px !important;
    }
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .badge-soft-success.badge.badge-success.badge-pill.progess-color,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .badge-soft-success.badge.badge-success.badge-pill.done-color,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table td .badge-soft-success.badge.badge-success.badge-pill.todo-color,
    .mytask-card.card .tab-content>.tab-pane span.font-size-12.badge-soft-success.badge.badge-success.badge-pill.complete-color,
    #layout-wrapper .main-content .page-content .card.Project-card .card-body .table-responsive table.table td span.font-size-12.badge-soft-success.badge.badge-success.badge-pill,
    .mytask-card.card .tab-content>.tab-pane span.font-size-12.badge-soft-success.badge.badge-success.badge-pill,
    span.font-size-12.badge-soft-success.badge.badge-success.badge-pill,
    span.font-size-12.badge-soft-warning.badge.badge-warning.badge-pill {
        width: 75px!important;
        font-size: 11px !important;
    }
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td.sml-text {
        width: 26%;
        padding-left: 10px !important;
        font-size: 12px !important;
    }
    #layout-wrapper .main-content .page-content .card .card-body ul.nav-tabs-custom.nav-justified.nav.nav-tabs li.nav-item a {
        padding: 0.4rem 1rem !important;
        font-size: 13px !important;
    }
    span.popup-form {
        font-size: 13px !important;
    }
    .chat-page .chat-leftsidebar .breadcrumb-sect ul li {
        padding: 8px 8px 8px 10px;
    }
    .chat-page .chat-leftsidebar .create-sect ul li {
        padding: 15px 3%;
        font-size: 0.75rem !important;
        width: 33%!important;
    }
    .chat-page .chat-leftsidebar .create-sect ul li:nth-child(1) {
        width: 33%!important;
    }
    .chatleft-attach table td:first-child,
    .chatleft-subtask .table-responsive td:first-child {
        width: 30px;
    }
    .chat-page .chatleft-dropfiles span i {
        margin-right: 10px;
    }
    .chat-page .chat-leftsidebar .chatleft-task h2.chat-task-h2,
    .chat-page .chat-leftsidebar .chatleft-task .chat-task-h2.h2,
    .chat-page .chat-leftsidebar .chatleft-subtask h2.chat-task-h2,
    .chat-page .chat-leftsidebar .chatleft-subtask .chat-task-h2.h2,
    .chatleft-attach h2 .chat-task-h2 {
        font-size: 1.4rem;
    }
    .chatleft-subtask .table-responsive td,
    .chatleft-attach td {
        border: none !important;
        font-size: 0.9rem;
        color: #414141;
        padding: 0.5rem 0.5rem;
    }
    .modal .modal-dialog.wide-modalsize.modal-dialog-centered {
        max-width: 90% !important;
    }
    .chat-page .chat-leftsidebar .chat-leftsidebar-first {
        max-height: 84vh;
    }
    .chat-page .chat-conver-outer .chat-conversation.p-3 {
        max-height: 67vh;
    }
    .chat-bottom-row.row {
        padding: 20px 0 10px 0;
    }
    .chat-page .user-chat .card {
        margin-bottom: 0 !important;
        height: 100vh;
    }
    .login-rightside .lg-sidetxt .h2.card-title {
        font-size: 3rem;
    }
    .loginpage .form-head a.logo-form {
        margin: 0 0 20px;
    }
    .loginpage .form-head a.logo-form img {
        width: 45px;
        height: 45px;
    }
    .loginpage .form-head .h2.card-title {
        margin: 0 0 15px;
        font-size: 2rem;
    }
    .loginpage .form-feilds .gogle-sect {
        margin: 30px 0;
    }
    .loginpage .login-leftside .card .loginform.card-body {
        width: 500px;
        max-width: 90%;
    }
    .loginpage .form-head a.logo-form {
        margin: 0 0 10px;
    }
    
    .loginpage .form-head a.logo-form img {
        width: 35px;
        height: 35px;
    }
    
    .loginpage .form-head .h2.card-title {
        font-size: 1.7rem;
        margin: 0 0 15px;
    }
    
    .loginpage .form-head p {
        font-size: 0.8rem;
    }
    
    .loginpage .form-feilds .gogle-sect {
        margin: 15px 0;
    }
    
    .loginpage .form-feilds .gogle-sect a.googlelink {
        line-height: 40px;
        height: 40px;
        font-size: 0.9rem;
        margin: 0 0 10px;
    }
    
    .loginpage .loginform .form-feilds .mb-3 {
        margin-bottom: 15px!important;
    }
    
    .loginpage .loginform .form-feilds .form-group label {
        font-size: 0.8rem;
    }
    
    .loginpage .loginform .form-feilds .form-group input.form-control {
        height: 40px;
        padding: 0 20px;
        line-height: 40px;
        font-size: 0.8rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check {
        font-size: .7rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check input[type=checkbox] {
        width: 14px;
        height: 14px;
        margin-right: 10px;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt a.text-muted {
        font-size: 0.7rem;
    }
    
    .loginpage .form-feilds button.btn.btn-primary.btn-block {
        line-height: 40px;
        height: 40px;
        font-size: 1rem;
    }
    .loginpage .login-leftside .card .loginform.card-body {
        width: 500px;
        max-width: 80%;
        top: 45%;
    }
    .loginpage .login-leftside .card .signin-ftr {
        padding: 0.6rem 1.25rem;
    }
    
    .loginpage .login-leftside .card .signin-ftr p {
        font-size: 0.6rem;
        margin:0 !important;
    }
    .main-content .full-calendar {
        min-height: 450px;
    }
    }
    
    @media screen and (min-width:1401px) and (max-width:1600px) {
    .navbar-header .d-flex a {
        font-size: 1.1rem !important;
        margin-left: 0 !important;
        padding: 10px 13px !important;
    }
    .navbar-header .d-flex:first-child {
        width: 85%!important;
        justify-content: left!important;
    }
    form.app-search.d-none.d-lg-block {
        margin-left: 2%!important;
        width: 22%!important;
    }
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tr td,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tr th {
        font-size: 14px;
        padding: 5px 5px;
    }
    #layout-wrapper .main-content .page-content .card .card-body ul.nav-tabs-custom.nav-justified.nav.nav-tabs li.nav-item a {
        padding: 0.75rem 0.5rem;
        font-size: 14px;
    }
    span.Medium-text,
    span.Low-text {
        font-size: 14px;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .btn-group label.btn.btn-primary {
        padding: 3px 5px;
    }
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 button.btn.btn-primary.btn-sm,
    .myevent-card.card .card-body .add-event .rightbar-title .col-md-2 .btn-group-sm>button.btn.btn-primary {
        width: 15px;
        height: 23px;
    }
    #layout-wrapper .main-content .container-fluid {
        padding: 40px 30px;
    }
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr th,
    #layout-wrapper .main-content .page-content .card .card-body .table-responsive table.table.mb-0.table tbody tr td {
        font-size: 14px;
    }
    .loginpage .form-head a.logo-form {
        margin: 0 0 20px;
    }
    .loginpage .form-head a.logo-form img {
        width: 45px;
        height: 45px;
    }
    .loginpage .form-head .h2.card-title {
        margin: 0 0 15px;
        font-size: 2rem;
    }
    .loginpage .form-feilds .gogle-sect {
        margin: 30px 0;
    }
    .loginpage .login-leftside .card .loginform.card-body {
        width: 500px;
        max-width: 90%;
    }
    .loginpage .form-head a.logo-form {
        margin: 0 0 10px;
    }
    
    .loginpage .form-head a.logo-form img {
        width: 35px;
        height: 35px;
    }
    
    .loginpage .form-head .h2.card-title {
        font-size: 1.7rem;
        margin: 0 0 15px;
    }
    
    .loginpage .form-head p {
        font-size: 0.8rem;
    }
    
    .loginpage .form-feilds .gogle-sect {
        margin: 15px 0;
    }
    
    .loginpage .form-feilds .gogle-sect a.googlelink {
        line-height: 40px;
        height: 40px;
        font-size: 0.9rem;
        margin: 0 0 10px;
    }
    
    .loginpage .loginform .form-feilds .mb-3 {
        margin-bottom: 15px!important;
    }
    
    .loginpage .loginform .form-feilds .form-group label {
        font-size: 0.8rem;
    }
    
    .loginpage .loginform .form-feilds .form-group input.form-control {
        height: 40px;
        padding: 0 20px;
        line-height: 40px;
        font-size: 0.8rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check {
        font-size: .7rem;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt .form-check input[type=checkbox] {
        width: 14px;
        height: 14px;
        margin-right: 10px;
    }
    
    .loginpage .loginform .form-feilds .rem-fgt a.text-muted {
        font-size: 0.7rem;
    }
    
    .loginpage .form-feilds button.btn.btn-primary.btn-block {
        line-height: 40px;
        height: 40px;
        font-size: 1rem;
    }
    .main-content .full-calendar {
        min-height: 500px;
    }
    navbar-header .d-flex:nth-child(1) {
        width: 88%;
    }
    form.app-search.d-none.d-lg-block {
        margin-left: 10%;
    }
    form.app-search.d-none.d-lg-block {
        margin-left: 2% !important;
        width: 20% !important;
    }
    .navbar-header .d-flex:first-child {
        width: 85% !important;
        justify-content: left !important;
    }
    }
    @media screen and (min-width:1601px) and (max-width:1919px) {
        form.app-search.d-none.d-lg-block {
            margin-left: 2% !important;
            width: 20% !important;
        }
        .navbar-header .d-flex:first-child {
            width: 85% !important;
            justify-content: left !important;
        }
    }
    @media screen and (min-width:768px) and (max-width:900px) {
        .navbar-header .d-flex a {
            font-size: .7rem !important;
            padding: 10px 7px !important;
            margin-left: 0 !important;
        }
    }
    @media screen and (min-width:600px) and (max-width:767px) {
        .navbar-header .d-flex a {
            font-size: .6rem !important;
            padding: 10px 5px !important;
            margin-left: 0 !important;
        }
        #layout-wrapper .main-content .container-fluid{
            padding-left: 15px;
        }
        #layout-wrapper footer.footer {
            margin-left: 0;
        }
        .navbar-header .d-flex:nth-child(1) {
            width: 83%;
        }
        form.app-search.d-none.d-lg-block {
            float: right !important;
            display: inline-block !important;
            margin-left: 7%;
            width: 20%;
        }
        .header-profile-user {
            height: 35px;
            width: 35px;
        }
        .header-item {
            height: 35px;
            width: 35px;
            padding: 5px;
    }
    }
    @media screen and (min-width:480px) and (max-width:599px) {
        .navbar-header .d-flex a {
            font-size: .55rem !important;
            padding: 10px 5px !important;
            margin-left: 0 !important;
        }
        #layout-wrapper .main-content .container-fluid{
            padding-left: 15px;
        }
        #layout-wrapper footer.footer {
            margin-left: 0;
        }
    }
    
    