.tl-col{
    display: inline-block;
    text-align: center;
}
.prgs{
    &.no-bg{
        .progress-bar{
            background-color: inherit;
        }
    }
    
}